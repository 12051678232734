import axios from "axios";

import { toast } from "react-toastify";
import store from "../../store/store";
import { openSessionModal } from "../../reducers/sessionHandler";
import { dateFormatter } from "../adminUtility";

export async function getLedgers(token, sortKey, page, formData, pageLimit) {
  // const categoryFieldName = formData.selectDropdown;
  const query = `
  query GetLedgers($pageInfo: PaginationInfo, $clientId: String, $studentId: String, $parent1Name: String, $parent2Name: String, $parentName: String, $studentName: String, $paymentNote: String, $tuition: Float, $className: String, $paid: String, $classId :Int) {
    getLedgers(pageInfo: $pageInfo, clientId: $clientId, studentId: $studentId, parent1Name: $parent1Name, parent2Name: $parent2Name, parentName: $parentName, studentName: $studentName, paymentNote: $paymentNote, tuition: $tuition, className: $className, paid: $paid , classId : $classId) {
      data {
        id
        ccExpiryAlert
        cardOnFileId      
          clientId
        studentId
        parent1_first_name
        parent1_last_name
        parent2_first_name
        parent2_last_name
        paid
        paymentnote
        proRatedFee
        student_first_name
        student_last_name
        currentInterval
        recurring_amount
        message
      is_recurring
      amount_to_pay
      totalInterval
      isVoidAllowed
        classes {
          name
          tuition
        }
      }
      totalCount
      totalPages
      limit
      currPage
    }
  }`;
  const data = formData?.map((item) => {
    return `${item.selectUser}:${item.name}`;
  });

  var variable = {};
  data.forEach(function (item) {
    var pair = item.split(":");

    variable[pair[0]] = pair[1];
  });

  formData.forEach((item) => {
    if (item.selectUser === "classId") {
      variable[item.selectUser] = parseInt(item.name);
    } else {
      variable[item.selectUser] = item.name;
    }
  });

  const variables = {
    pageInfo: {
      isAsc: sortKey?.isAsc,
      limit: parseInt(pageLimit),
      page: page,
      sort: sortKey?.sortId === null ? null : sortKey?.sortId?.toString(),
    },
    ...variable,
  };

  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: query,
        variables: variables,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (
      response.data.errors &&
      response.data.errors[0]?.message ===
        "Error getting ledgers: Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }

    return response?.data?.data?.getLedgers;
  } catch (error) {
    console.error("Error fetching ledger details from GraphQL", error);
    throw error;
  }
}

export async function adminLedgerDetails(token, ledgerID) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + `/admin/ledger-details/${ledgerID}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }

    return response?.data;
  } catch (error) {}
}

// pay ledger

export async function ledgerPaymentAPI(token, ledgerID, APIDATA) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + `/admin/pay-ledger/${ledgerID}`,

      APIDATA,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }

    return response;
  } catch (error) {
    toast.error(error?.response?.data?.error, {
      toastId: "error",
    });
  }
}

export async function recurringPaymentAPI(
  token,
  ledgerID,
  APIDATA,
  classScheduleId
) {
  try {
    const response = await axios.put(
      process.env.REACT_APP_BASE_URL +
        `/admin/ledger/update-recurring/${ledgerID}/${classScheduleId}`,

      APIDATA,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }

    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.error, {
      toastId: "error",
    });
  }
}

// ENABLE RECURIRNG FOR ORDERS
export async function enableRecurringOrders(token, orderId, APIDATA) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + `/admin/enable-recurring/${orderId}`,

      APIDATA,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }

    return response;
  } catch (error) {
    toast.error(error?.response?.data?.error, {
      toastId: "error",
    });
  }
}

export async function ledgerInvoiceDownload(token, ledgerID) {
  try {
    const response = await axios.get(
      process.env.REACT_APP_BASE_URL + `/get-ledger-invoice/${ledgerID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }

    return response;
  } catch (error) {
    toast.error(error?.response?.data?.error, {
      toastId: "error",
    });
  }
}

// Open Enable Button Api Call
export async function enableButtonAPI(token, ledgerID) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL +
        `/admin/ledger-recurring-details/${ledgerID}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }

    return response?.data;
  } catch (error) {}
}

// BALANCE HISTORY
export async function ledgerBalanceHistory(token, ledgerID) {
  try {
    const response = await axios.get(
      process.env.REACT_APP_BASE_URL + `/admin/get-payment-history/${ledgerID}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }

    return response?.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

// ADHOC CHARGES :
export async function addCharges(token, ledgerID, payload) {
  const data = {
    adhocs: payload,
  };

  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + `/admin/ledger-adhoc/${ledgerID}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }

    return response.data;
  } catch (error) {
    console.error("Error adding charges:", error);
    throw error;
  }
}

// DELETE CARD ON FILE -CARDS(RECURRING)
export const deleteCardOnFile = async (token, cardID) => {
  try {
    const response = await axios.delete(
      process.env.REACT_APP_BASE_URL + `/admin/card-on-file/${cardID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }

    return response?.data;
  } catch (error) {
    console.error("Error deleting student:", error);
    throw error;
  }
};

// get Card on File
export const getAllCards = async (token, ledgerID) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_BASE_URL +
        `/admin/card-on-file/get?ledgerId=${ledgerID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }

    return response?.data;
  } catch (error) {
    console.error("Error deleting student:", error);
    throw error;
  }
};

export const getAllCardForOrder = async (token, orderId) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_BASE_URL +
        `/admin/card-on-file/get?orderId=${orderId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }

    return response?.data;
  } catch (error) {
    console.error("Error deleting student:", error);
    throw error;
  }
};

//DELETE LEDGER

export const deleteLedger = async (token, ledgerId) => {
  try {
    const response = await axios.delete(
      process.env.REACT_APP_BASE_URL + `/admin/ledger/delete/${ledgerId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting student:", error);
    throw error;
  }
};

// Edit Ledger

export async function editLedgerAPI(token, ledgerId, paymentNoteValue) {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/admin/ledger/update/${ledgerId}`,
      {
        paymentnote: paymentNoteValue,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }

    return response.data;
  } catch (error) {
    console.error("Error updating ledger:", error);
    throw error;
  }
}

// void Ledger

export async function voidPayment(token, ledgerId, date) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/admin/ledger/void-transaction`,
      {
        ledgerId: ledgerId,
        date: date,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }

    return response?.data;
  } catch (error) {
    console.error("Error updating ledger:", error);
    throw error;
  }
}

export async function voidPaymentButtonAPI(token, selectedLedgerID) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/admin/transactions/${selectedLedgerID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response;
  } catch (error) {}
}

export async function addVoidPayment(token, selectedCheckbox) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/admin/ledger/void-transaction`,
      {
        ids: selectedCheckbox,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response;
  } catch (error) {
    toast.error(error.response.data.error);
  }
}

// Repay

export async function repayPayment(token, data) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/admin/ledger/repay-void`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response;
  } catch (error) {
    toast.error(error.response.data.error);
  }
}

// Balance  - Ledger
export async function getOrderLogs(token, orderId) {
  const query = `
    query GetClientLogs($orderId: Int) {
      getClientLogs(orderId: $orderId) {
        id
        fk_user_client_logs
        check_number
        memo
        amount_tendered
        payment_date
        message
        fk_ledger_client_logs
        card
      }
    }
  `;

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/graphql`,
      {
        query: query,
        variables: {
          orderId: orderId,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data;
  } catch (error) {
    console.error("Error fetching client logs from GraphQL", error);
    throw error;
  }
}

// Weekly Class Rcurring Adding

export const weeklyLedgerAPI = async (
  selectedLedgerID,
  campID,
  token,
  APIDATA
) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/admin/ledger/update-recurring-camp/${selectedLedgerID}/${campID}`,
      APIDATA,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response;
  } catch (error) {
    return error;
  }
};

export const recurringStatusAPI_MONTHLY = async (
  data,
  token,
  selectedLedgerID
) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/admin/recurring/status`,
      {
        ...data,
        ledgerId: selectedLedgerID,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response;
  } catch (error) {
    toast.error(error.response.data.error);
  }
};

export const recurringStatusAPI_ORDERS = async (
  data,
  token,
  selectedLedgerID
) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/admin/order-recurring/status`,
      {
        ...data,
        orderId: selectedLedgerID,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response;
  } catch (error) {
    toast.error(error.response.data.error);
  }
};

export const recurringStatusAPI_WEEKLY = async (
  data,
  token,
  selectedLedgerID
) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/admin/recurring/status`,
      {
        campId: data?.classScheduleId,
        status: data?.status,
        ledgerId: selectedLedgerID,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response;
  } catch (error) {
    toast.error(error.response.data.error);
  }
};

// GET adhoc Details
export const getAdhocDetails = async (token, ledgerID, adhocArray) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/admin/get-ledger-adhoc/${ledgerID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  } catch (error) {
    if (
      error?.response?.data?.error &&
      error?.response?.data?.error === "Unauthorized"
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    toast.error(error?.response?.data?.error, {
      toastId: "error",
    });
  }
};

export const deleteAdhocDetail = async (token, deleteID, message) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/admin/delete-ledger-adhoc/${deleteID}?message=${message}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.error, {
      toastId: "error",
    });
  }
};

// DELETING SPECIFIC ADHOC OF  PREFILLED
export const deletePrefilledAdhoc = async (token, row) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/admin/update-ledger-adhoc/${row?.id}`,
      {
        category: row?.categoryId,
        date: dateFormatter(row?.chargeDate),
        memo: row?.memo,
        amount: row?.charge,
        discount: row?.discount,
        totalAmount: row?.total,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response?.data;
  } catch (error) {
    if (
      error?.response?.data?.error &&
      error?.response?.data?.error === "Unauthorized"
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }

    toast.error(error?.response?.data?.error, {
      toastId: "error",
    });
  }
};
