import axios from "axios";

import {
  GET_ALL_STUDENT_ENROLLMENTS,
  GET_CLIENT,
} from "../Queries/ParentProfile";

export async function getAllStudentEnrollments(token) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_ALL_STUDENT_ENROLLMENTS,
        variables: {},
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    console.error("Error fetching student data from GraphQL", error);
    throw error;
  }
}

export async function getClients(token) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_CLIENT,
        variables: {},
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response?.data?.data;
  } catch (error) {
    console.error("Error fetching student data from GraphQL", error);
    throw error;
  }
}

export async function getUpdateParent(formData, token) {
  try {
    const response = await axios.put(
      process.env.REACT_APP_BASE_URL + "/client/update",
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response?.data;
  } catch (error) {
    return error;
  }
}

export async function getInvoice(orderId, token, isBirthday) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/admin/invoice",
      {
        orderId: orderId,
        isBirthdayInvoice: isBirthday,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {}
}
