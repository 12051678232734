import axios from "axios";
import { GET_CATEGORIES } from "../adminQueries/Categories";
import { toast } from "react-toastify";
import store from "../../store/store";
import { openSessionModal } from "../../reducers/sessionHandler";

export async function getPrograms(token, formData, page, sortKey, pageLimit) {
  const categoryFieldName =
    formData.categoryName === "name" ? "name" : "schoolName";

  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_CATEGORIES,
        variables: {
          pageInfo: {
            limit: parseInt(pageLimit),
            page: page,
            sort: sortKey?.sortId === null ? null : sortKey?.sortId?.toString(),
            isAsc: sortKey?.isAsc,
          },
          [categoryFieldName]: formData.name,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data?.data?.getCategories;
  } catch (error) {
    console.error("Error fetching program details from GraphQL", error);
    toast.error(error?.response?.data?.error, {
        toastId: "error",
      });
    throw error;
  }
}

export async function createCategory(formData, token) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/admin/category/create",
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      return { error: error.response.data.error };
    } else {
      return { error: "An error occurred" };
    }
  }
}

export async function updateCategory(editData, token, id) {
  try {
    const response = await axios.put(
      process.env.REACT_APP_BASE_URL + `/admin/category/update/${id}`,
      editData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.error, {
        toastId: "error",
      });
    return error;
  }
}

export const deleteCategory = async (data, token) => {
  try {
    const response = await axios.delete(
      process.env.REACT_APP_BASE_URL + `/admin/category/delete/${data}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.error, {
        toastId: "error",
      });
    throw error;
  }
};

export default getPrograms;
