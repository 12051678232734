import React from "react";
import aboutUsBanner from "../../parent-assets/images/home-banner.jpg";
import aboutImg1 from "../../parent-assets/images/about-us-img01.png";
import birthday_option from "../../parent-assets/images/birthday-option.jpg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { getSectionCMS } from "../../../Admin/adminApi/mastermenu";
import { toast } from "react-toastify";
import axios from "axios";
import CarasouelShimmer from "../../parent-components/Shimmers/CarasouelShimmer";
import AboutUsShimmer from "../../../Admin/components/ShimmerUi/AboutUsShimmer";

function PartyPage() {
  const token = localStorage.getItem("access_token");
  const [sectionData, setSectionData] = useState([]);
  const [showPartyImage, setShowPartyImage] = useState(false);
  const [galleryImage, setGalleryImage] = useState();
  const [showCarasouel, setShowCarasouel] = useState(false);
  const [showShimmer, setShowShimmer] = useState(false);
  const navigate = useNavigate();

  const getPartyImages = async () => {
    setShowPartyImage(false);
    setShowShimmer(true);
    const section = await getSectionCMS(token, 174);
    setSectionData(section);
    setShowShimmer(false);
    setShowPartyImage(true);
  };

  useEffect(() => {
    getPartyImages();
    getBirthdayTitle();
  }, []);

  const getBirthdayTitle = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + `/admin/get_facilities`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const titleArray = response?.data?.data
        ?.filter((item) => item?.facility === "BIRTHDAYS")
        ?.map((item) => item?.id)
        ?.flat();

      if (response?.status === 201 || response?.status === 200) {
        getFacilityGalleryImage(titleArray?.[0]);
      }
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };
  const getFacilityGalleryImage = async (id) => {
    try {
      const postData = {
        facility_id: parseInt(id),
      };
      setShowCarasouel(false);
      const responseImage = await axios.post(
        process.env.REACT_APP_BASE_URL + `/admin/get_gallery`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowCarasouel(true);
      const imagesArray = responseImage.data.data
        .map((item) => item.image)
        .flat();
      setGalleryImage(imagesArray);
    } catch (error) {
      console.log("error");
    }
  };
  useEffect(() => {
    const fancyboxOptions = {};
    Fancybox.bind("[data-fancybox='gallery']", fancyboxOptions);
  }, []);

  useEffect(() => {
    if (token) {
      const sessionTimeOut = setTimeout(() => {
        localStorage.removeItem("access_token");
        navigate("/");
      }, 86400000);
      return () => clearTimeout(sessionTimeOut);
    }
  }, [navigate, token]);

  const responsiveOptions = {
    0: {
      items: 1,
    },
    768: {
      items: 3,
    },
  };

  return (
    <>
      {showShimmer ? (
        <AboutUsShimmer />
      ) : (
        <div className="contentwraper">
          <section style={{ position: "relative" }}>
            <section className="siteBanner" style={{ background: "#EA519C" }}>
              <div className="siteBanner__overlay">
                <img
                  className="siteBanner__img"
                  src={
                    sectionData?.length > 0
                      ? sectionData[0]?.imageSection?.image
                      : aboutUsBanner
                  }
                  alt=""
                />
              </div>
            </section>
          </section>
          <section className="camp_sec">
            <section className="breadcrumbSec">
              <div className="container">
                <div className="breadcrumb">
                  <div className="breadcrumb__item">
                    <Link
                      className="breadcrumb__link"
                      onClick={() => navigate(-1)}
                    >
                      Back to results
                    </Link>
                  </div>
                </div>
              </div>
            </section>
            <section className="aboutusSec">
              <div className="container ">
                <div className="aboutData">
                  <div className="aboutData__box">
                    <div className="aboutData__row">
                      <div className="aboutData__col">
                        <div className="content">
                          <h1
                            className="title title--h1 title--extrabold"
                            style={{ marginBottom: "1.4rem" }}
                          >
                            Lets Party!
                          </h1>
                          <div className="shortText">
                            <p>
                              {sectionData?.length > 0 &&
                                sectionData[1]?.descriptionSection?.description}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="aboutData__col">
                        <div className="picture picture--cliplayerBlue picture--cliplayerBlue--afterNone">
                          <img
                            className="picture__img birthday_page_picture_img"
                            src={
                              sectionData?.length > 0
                                ? sectionData[1]?.imageSection?.image
                                : aboutImg1
                            }
                            alt="About Us"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="aboutData__box">
                    <h2
                      className="title title--h1 title--extrabold"
                      style={{ textAlign: "center", marginBottom: "1.4rem" }}
                    >
                      There’s Playtime, <span>and then there’s GYMTIME !</span>
                    </h2>
                    <div className="aboutData__row">
                      <div className="aboutData__col">
                        <div className="content">
                          <div className="shortText">
                            <p>
                              {sectionData?.length > 0 &&
                                sectionData[3]?.descriptionSection?.description}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="aboutData__col">
                        <div className="picture picture--cliplayerPink picture--cliplayerPink--afterNone">
                          <img
                            className="picture__img_party"
                            src={
                              sectionData?.length > 0
                                ? sectionData[3]?.imageSection?.image
                                : birthday_option
                            }
                            alt="About Us"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {showCarasouel ? (
              <section className="facilitiesSec party_carousel">
                <div className="container">
                  <h1 className="title title--extrabold">Take a Look!</h1>
                  {/* <CarasouelShimmer /> */}
                  <OwlCarousel
                    margin={10}
                    responsiveclassname="true"
                    responsive={responsiveOptions}
                    className="galleryGrid owl-carousel"
                    autoplay
                    loop={true}
                    arrows="true"
                    dots={false}
                    nav={true}
                    lazyLoad={true}
                    navText={[
                      `<img src=${require("../../parent-assets/images/enrichment-images/arrow-left.png")} />`,
                      `<img src=${require("../../parent-assets/images/enrichment-images/arrow-right.png")} alt='right' />`,
                    ]}
                  >
                    {galleryImage?.map((item) => (
                      <div className="galleryGrid__item">
                        <div className="galleryGrid__card">
                          <a
                            className="galleryGrid__card__link"
                            data-fancybox="gallery"
                            href={item}
                          >
                            <img
                              className="galleryGrid__card__img"
                              src={item}
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </section>
            ) : (
              <CarasouelShimmer />
            )}

            <section className="commonSec commonSec_mobile_version">
              <div className="container ">
                <h3 className="title title--extrabold birthday_container">
                  FAQs
                </h3>
                <div className="faqsData">
                  <div className="faqs ">
                    {" "}
                    <div className="faq ques_ans_section">
                      <h1 className="faq_question">
                        {" "}
                        <span className="ques_subtitle">1.</span> Are there
                        kosher options?
                      </h1>
                      <h2 className="faq_answer">
                        Yes, kosher pizza is available and is ordered from
                        Saba’s. The BTW Bakery cake (included in our All
                        Inclusive package) is Kosher.
                      </h2>
                    </div>
                    <div className="faq ques_ans_section">
                      <h1 className="faq_question">
                        {" "}
                        <span className="ques_subtitle">2.</span> Are there any
                        discounts?
                      </h1>
                      <h2 className="faq_answer">
                        Unfortunately there are no discounts for current
                        students or gymnasts.
                      </h2>
                    </div>
                    <div className="faq ques_ans_section">
                      <h1 className="faq_question">
                        {" "}
                        <span className="ques_subtitle">3.</span> Can I bring in
                        outside food and beverages?
                      </h1>
                      <h2 className="faq_answer">
                        Yes, you can! We ask that you are aware of any allergies
                        in your party (we are a nut-free facility).
                      </h2>
                    </div>
                    <div className="faq ques_ans_section">
                      <h1 className="faq_question">
                        <span className="ques_subtitle">4.</span> How many
                        minutes prior to party start time can I enter the
                        facility?
                      </h1>
                      <h2 className="faq_answer">
                        The party family can enter the facility 15 minutes prior
                        to party start time. The staff will take care of the set
                        up and clean up.
                      </h2>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      gap: "20px",
                    }}
                  >
                    <div className="btnWrap">
                      <button
                        className="btn"
                        onClick={() => {
                          navigate("/birthday");
                        }}
                        style={{ fontStyle: "oblique" }}
                      >
                        Book Now!
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      )}
    </>
  );
}

export default PartyPage;
