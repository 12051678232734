import { gql } from "@apollo/client";

const GET_CLASS = `
query GetClass($classId: ID!) { getClass(classId: $classId) {
   id 
   end_date 
   name 
   max_strength 
   tuition
   category {
    name
   }
   class_schedules 
   { 
    createdat 
    end_time 
    classes_enrolled 
    { 
    id 
  } 
  }
   } 
  }
`;

const GET_CLASS_DETAILS = `
query GetClass($name: String, $categoryId: String, $classId: Int , $minAge: Int , $maxAge:  Int ,  $isCombo : Boolean, $programId : Int) {
  getClass(name: $name, categoryId: $categoryId, classId: $classId, minAge: $minAge , maxAge: $maxAge ,  isCombo : $isCombo , programId : $programId) {
    name
    id
    end_date
    start_date
    fk_category_classes
    fk_season_classes
    fk_teachers_classes
    gender
    hidden
    max_age
    min_age
    max_strength
    period
    tuition
    website
    weekday
    image
    is_combo
    category {
      name
    }
    class_schedules {
      name
      tuition
      end_time
      start_time
      id
      weekday
      classScheduleId
      subject
    }    
    classes_enrolled {
      id
      fk_class_schedule_classes_enrolled
      fk_student_classes_enrolled
    }
  }
}
`;

export { GET_CLASS, GET_CLASS_DETAILS };
