import axios from "axios";
import {
  CLASS_BY_MONTHLY_SWITCH_QUERY,
  CLASS_BY_WEEKLY_SWITCH_QUERY,
  GET_CLASS,
  GET_CLIENTS,
  GET_SCHEDULE_NEW,
  GET_STUDENT_DETAILS,
} from "../adminQueries/Clients";
import { GET_CATEGORY } from "../adminQueries/Clients";
import store from "../../store/store";
import { openSessionModal } from "../../reducers/sessionHandler";
import { toast } from "react-toastify";

export async function getClients(token, formData, page, sortKey, pageLimit) {
  try {
    const data = formData?.map((item) => {
      return `${item.selectUser}:${item.name}`;
    });

    var variables = {};
    data.forEach(function (item) {
      var pair = item.split(":");

      variables[pair[0]] = pair[1];
    });

    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_CLIENTS,
        variables: {
          // category: "",
          // name: formData.name,
          pageInfo: {
            isAsc: sortKey?.isAsc,
            limit: parseInt(pageLimit),
            page: page,
            sort: sortKey?.sortId == null ? null : sortKey?.sortId?.toString(),
          },
          ...variables,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data?.data.getClientForAdminPanel;
  } catch (error) {
    console.error("Error fetching program details from GraphQL", error);
    throw error;
  }
}

export async function getcategory(token, formData, page) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_CATEGORY,
        variables: {
          pageInfo: {
            limit: 1000,
            page: 0,
            sort: "1",
            isAsc: true,
          },
          name: "",
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data?.data.getCategories;
  } catch (error) {
    console.error("Error fetching program details from GraphQL", error);
    throw error;
  }
}

export async function getclass(categoryId, token) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_CLASS,
        variables: {
          searchId: categoryId,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data?.data?.getClasses;
  } catch (error) {
    console.error("Error fetching program details from GraphQL", error);
    throw error;
  }
}

export async function getSchedule(classId, categoryId, formData, token, page) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_SCHEDULE_NEW,
        variables: {
          name: classId,
          categoryId: categoryId,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data?.data.getClass;
  } catch (error) {
    console.error("Error fetching program details from GraphQL", error);
    throw error;
  }
}

export async function createCategory(formData, token) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/admin/category/create",
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data;
  } catch (error) {
    return error;
  }
}

export async function updateCategory(editData, token, id) {
  try {
    const response = await axios.put(
      process.env.REACT_APP_BASE_URL + `/admin/category/update/${id}`,
      editData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data;
  } catch (error) {
    return error;
  }
}

export const deleteClient = async (data, token) => {
  try {
    const response = await axios.delete(
      process.env.REACT_APP_BASE_URL + `/admin/client/delete/${data}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response.data;
  } catch (error) {
    console.error("Error deleting student:", error);
    throw error;
  }
};

export const deleteStudent = async (data, token) => {
  try {
    const response = await axios.delete(
      process.env.REACT_APP_BASE_URL + `/admin/student/delete/${data}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response.data;
  } catch (error) {
    console.error("Error deleting student:", error);
    throw error;
  }
};

export const deleteComment = async (data, token) => {
  try {
    const response = await axios.delete(
      process.env.REACT_APP_BASE_URL + `/admin/comment/delete/${data}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response.data;
  } catch (error) {
    console.error("Error deleting student:", error);
    throw error;
  }
};

export async function getScheduleNew(classId, formData, token, page) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_SCHEDULE_NEW,
        variables: {
          classId: classId,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data?.data?.getClass;
  } catch (error) {
    console.error("Error fetching program details from GraphQL", error);
    throw error;
  }
}
export default getClients;

export async function getAllCategories(token, formData, page) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_CATEGORY,
        variables: {
          pageInfo: {
            limit: 1000,
            page: 0,
            sort: "schoolName",
            isAsc: true,
          },
          name: "",
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data?.data.getCategories;
  } catch (error) {
    console.error("Error fetching program details from GraphQL", error);
    throw error;
  }
}

// Warning For Clientrs
export async function getWarningForClients(token, clientId) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/admin/client/delete-warning/${clientId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response.data;
  } catch (error) {
    console.error("Error fetching student delete warning:", error);
    throw error;
  }
}

// Class Schedule Detials by Class ID
export async function getClassScheduleDetails(classId, token) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_SCHEDULE_NEW,
        variables: {
          classId: classId,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data?.data?.getClass;
  } catch (error) {
    console.error("Error fetching program details from GraphQL", error);
    throw error;
  }
}

// Add Student

export async function addStudent(data, payload, token) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + `/admin/student/create/${data}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.error);
  }
}

//MONTHLY CLASS - STUDENT MODAL for SWITHC CLASS
export async function getMontlyClassForSwitch(token) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: CLASS_BY_MONTHLY_SWITCH_QUERY,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response?.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }

    // Return the fetched classes data
    return response?.data?.data?.getClassesOnly;
  } catch (error) {
    console.error("Error fetching program details from GraphQL", error);
    throw error;
  }
}
export async function getWeeklyClassForSwitch(token) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: CLASS_BY_WEEKLY_SWITCH_QUERY,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response?.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }

    // Return the fetched classes data
    return response?.data?.data?.getCampWeekOnly;
  } catch (error) {
    console.error("Error fetching program details from GraphQL", error);
    throw error;
  }
}

export async function getStudentDetailsAPI(token, studentID) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_STUDENT_DETAILS,
        variables: {
          studentId: parseInt(studentID),
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response?.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }

    // Return the fetched classes data
    return response?.data?.data;
  } catch (error) {
    console.error("Error fetching program details from GraphQL", error);
    throw error;
  }
}

// save for Swithcing Class
export async function saveSwitchingClass(payload, token) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + `/admin/class/switch-class`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.error);
    return error;
  }
}
export async function saveWeeklySwitchingClasss(payload, token) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + `/admin/class/switch-camp`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.error);
    return error;
  }
}

export const editMakeup = async (token, details, value) => {
  try {
    const response = await axios.put(
      process.env.REACT_APP_BASE_URL + `/admin/scheduled-makeups/edit`,
      { details: details, id: value?.id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data;
  } catch (error) {
    toast.error(error.response?.data?.error, {
      toastId: "error",
    });

    return error;
  }
};

// Weekly Class Schedule table data for ===> Switching Class
export async function getWeeklyClassByID(token, classID) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: `query getClassesQuery($classId: Int) {
          getWeeklyClass(classId: $classId) {
            name
            id
            name
            start_time
            end_time
            start_date
            end_date
            max_strength
            category_name
            tuition
            halfDay {
                id
                name
                week
                start_date
                end_date
                max_strength
                tuition
                start_day
                end_day
                enrolled
                start_time
                end_time
                class_type
            }
            fullDay {
                id
                name
                week
                start_date
                end_date
                max_strength
                tuition
                start_day
                end_day
                enrolled
                start_time
                end_time
                class_type
            }
        }        }`,
        variables: {
          classId: parseInt(classID),
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data;
  } catch (error) {
    console.error(error);
  }
}
