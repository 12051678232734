import React, { useEffect, useRef } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import DeleteUser from "../UserModals/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { setAdminModalContentReducer } from "../../../reducers/admin-slice/adminModalsSlice";
import { updateAttendence } from "../../adminApi/attendance";
import { toast } from "react-toastify";
import EditProgramModal from "../../pages/CMS/ProgramModals/EditProgramModal";
import EditClassModal from "../../pages/ClassModals/EditClassModal";
import hideIcon from "../../../Admin/assets/images/icons/hide icon.png";
import show from "../../../Admin/assets/images/icons/show.png";
import axios from "axios";
import { ReactComponent as DeleteRowIcon } from "../../assets/images/icons/deleteRowIcon.svg";
import { ReactComponent as EditRowIcon } from "../../assets/images/icons/editRowIcon.svg";
import CampCmsModal from "../CampCmsModal/CampCmsModal";
import { getCampProgramClassDetails } from "../../adminApi/program";
import { getCampProgramApi } from "../../../Parent/api/classApi";
import deleteIcon from "../../../Parent/parent-assets/images/cmsDelete.svg";
import DeleteClassModal from "./DeleteClassModal";
function TableNew({
  headerData = [],
  bodyData = [],
  totalPages,
  tableName,
  tableType,
  setSortKey,
  setPageLimit,
  setManageData,
  pageLimit,
  totalCount,
  currentPage,
  setTableName,
  setManageDataId,
  setClassProgramId,
  setClassProgramName,
  setAddClassId,
  dummayManageProgramData,
  allClassData,
  activeAccordion,
  setPdfStateValue,
  getManageDataFunction,
  loadingState,
  setIsEnrichmentCombos,
  isEnrichmentCombo,
  setEnrichmentManageClass,
  setManageEnrichmentData,
  enrichmentManageClass,
  setEnrichmentData,
  setIndexNumber,
  indexNumber,
  setDummyManageProgramData,
  setAllClassData,
  setAgeRangedRange,
  getMasterProgramMenuData,
  handleSearch,
  scrollToTopHandler,
  enrichmentCategoryId,
  setEnrichemntCategoryId,
}) {
  const token = localStorage.getItem("access_token");
  const [categoryItemForCamp, setCategoryForCamp] = useState();
  const [showIcon, setShowIcon] = useState([]);
  const [campProgramName, setCampProgramName] = useState("");
  const [activePage, setActivePage] = useState(0);
  const [deleteCategoryModal, setDeleteCategoryModal] = useState(false);
  const [categoryData, setCategoryData] = useState();
  const [loading, setLoading] = useState(false);
  const [showCMSEditModal, setShowCMSEditModal] = useState(false);
  const [programId, setProgramId] = useState();
  const [teamsAliasId, setTeamsAliasId] = useState();
  const [programName, setProgramName] = useState();
  const [isAsc, setIsAsc] = useState(true);
  const [programClassEditModal, setProgramClassEditModal] = useState(false);
  const [className, setClassName] = useState();
  const [isPresentArray, setIsPresentArray] = useState([]);
  const [isClassesShown, setIsClassesShown] = useState([]);
  const [classId, setClassId] = useState();
  const [prevImage, setPrevImage] = useState();
  const [prevDescription, setPrevDescription] = useState();
  const [showCampModal, setShowCampModal] = useState(false);
  const [campClassDetails, setCampClassDetails] = useState([]);
  const [campClassData, setCampClassData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [categoryItems, setCategoryItems] = useState();
  const [programs, setPrograms] = useState();
  const [deleteType, setDeleteType] = useState();
  const [selectedClassDetails, setSelectedClassDetails] = useState();
  // const[enrichmentCategoryId,setEnrichemntCategoryId]=useState();
  const scrollContainerRef = useRef(null);
  const dispatch = useDispatch();
  const role = useSelector((state) => state?.userRole?.RoleId);
  const [programHide, setProgramHide] = useState();

  const handlePageChange = (selectedPage) => {
    // if(notification && notification==="notification"){
    //   setCheckboxes();
    // }
    setActivePage(selectedPage.selected);
    dispatch(setAdminModalContentReducer(selectedPage.selected));
    // if(tableName==="Attendance"){
    //   handleSearch();
    // }
  };

  const handleHeaderClick = (i, index) => {
    if (tableName === "Notification" && index !== 0) {
      setSortKey({
        sortId: index,
        isAsc: !isAsc,
      });
    }

    if (i.icon) {
      setIsAsc(!isAsc);
      setSortKey({
        sortId: index,
        isAsc: !isAsc,
      });
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }
  };

  const attendenceHandler = async (index) => {
    const categoryItem = bodyData[index];
    const newIsPresentArray = [...isPresentArray];
    newIsPresentArray[index] = !isPresentArray[index];
    setIsPresentArray(newIsPresentArray);

    const prs = {
      is_present: String(!isPresentArray[index]),
    };
    const response = await updateAttendence(
      token,
      categoryItem?.item?.class_id,
      categoryItem?.item?.student_id,
      prs,
      categoryItem?.item?.attendanceId
    );
    if (response?.status) {
      toast.success("Attendence updated successfully..");
    }
  };

  const hideClassHandler = async (index, categoryItems, id, programName) => {
    const token = localStorage.getItem("access_token");
    if (categoryItems?.data?.[1] === "CHEER CAMP") {
      const newIsHideClassArray = [...isClassesShown];
      newIsHideClassArray[index] = !newIsHideClassArray[index];
      setIsClassesShown(newIsHideClassArray);
      const data = {
        hidden: !id ? 1 : 0,
        campId: categoryItems?.data?.[0],
      };
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + `/admin/hide_unhide_camp`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else if (programName !== "Camp") {
      const newIsHideClassArray = [...isClassesShown];
      newIsHideClassArray[index] = !newIsHideClassArray[index];
      setIsClassesShown(newIsHideClassArray);

      const postData = {
        classId: categoryItems?.data?.[0],
        hidden: !id ? 1 : 0,
      };
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + `/admin/hide_unhide_class_cms`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else {
      const newIsHideClassArray = [...isClassesShown];
      newIsHideClassArray[index] = !newIsHideClassArray[index];
      setIsClassesShown(newIsHideClassArray);
      const data = {
        hidden: !id ? 1 : 0,
        campId: categoryItems?.data?.[0],
      };
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + `/admin/hide_unhide_camp`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }
  };

  const editCmsProgramHandler = (categoryItem) => {
    setPrevDescription(categoryItem?.item?.description);
    setPrevImage(categoryItem?.item);
    setShowCMSEditModal(true);
    setProgramId(categoryItem?.item?.id);

    setProgramName(categoryItem?.item?.name || categoryItem?.item?.age_range);
  };

  useEffect(() => {
    setProgramHide(bodyData?.map((item) => item?.item?.is_active));
    setIsPresentArray(bodyData?.map((item) => item?.item?.is_present || false));
    setIsClassesShown(
      dummayManageProgramData?.map((item) => item?.hidden || false)
    );
    if (programName === "Enrichment Combos" && enrichmentManageClass) {
      setIsClassesShown(
        dummayManageProgramData?.map((item) => item?.hidden || false)
      );
    }
  }, [bodyData]);

  useEffect(() => {
    getCampClassData();
  }, []);

  const getCampClassData = async () => {
    const getProgramMangeData = await getCampProgramApi();
    setCampClassData(getProgramMangeData?.data);
  };

  const manageClassesHandler = (categoryItem) => {
    scrollToTopHandler();
    setCampProgramName(categoryItem?.item?.name);
    setIsEnrichmentCombos(categoryItem?.item?.name);
    setManageData(true);
    setTableName("Class's Program");
    setManageDataId(categoryItem?.item?.id);
    if (activeAccordion === 1) {
      if (categoryItem?.item?.age_range === "0-12") {
        setAgeRangedRange("0-1");
      } else if (categoryItem?.item?.age_range === "12-24") {
        setAgeRangedRange("1-2");
      } else {
        setAgeRangedRange(categoryItem?.item?.age_range);
      }
    }

    setProgramName(categoryItem?.item?.name || categoryItem?.item?.age_range);
    setProgramId(categoryItem?.item?.id);
    setClassProgramId(categoryItem?.item?.id);
    setClassProgramName(
      categoryItem?.item?.name || categoryItem?.item?.age_range
    );
  };

  const editClassProgramHandler = (categoryItem) => {
    setClassName(categoryItem?.data[1] || categoryItem?.item?.age_range);
    setClassId(categoryItem?.data[0]);
    setSelectedClassDetails(categoryItem);
    // setProgramName(categoryItem?.data[2] || categoryItem?.item?.age_range);
  };

  const tagID = document.getElementById("tablePdfContent");
  if (tableName === "Attendance") {
    setPdfStateValue(tagID);
  }

  const enrichmentClassHandler = async (categoryItem) => {
    setEnrichemntCategoryId(categoryItem?.data?.[0]);
    scrollToTopHandler();
    setEnrichmentManageClass(true);
    const postData = {
      categoryId:
        categoryItem?.data?.[0] === undefined
          ? categoryItem
          : `${categoryItem?.data?.[0]}`,
    };
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + `/admin/getClassesCMSAdminForCombo2`,
      postData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setAllClassData(response?.data?.data);
    setEnrichmentData(response?.data?.data);
    setDummyManageProgramData(response?.data?.data);
    const enrichmentManageData = response?.data?.data?.map(
      (enrichmentInnerManegeData, index) => {
        return {
          data: [
            enrichmentInnerManegeData?.id ? enrichmentInnerManegeData?.id : "-",
            enrichmentInnerManegeData?.name
              ? enrichmentInnerManegeData?.name
              : "-",
            "Gymtime",
          ],
        };
      }
    );
    setManageEnrichmentData(enrichmentManageData);
    // setEnrichemntCategoryId(null);
  };

  const campClassHanlder = async (data) => {
    const response = await getCampProgramClassDetails(data?.data?.[0]);
    setCampClassDetails(response?.data?.data?.[0]);
  };

  const teamLandingPageHandler = async (id, categoryItem) => {
    try {
      const postData = {
        programId: categoryItem?.item?.alias_id,
        sub_program_cms1_id: parseInt(id),
      };
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + `/admin/update_program_for_landing`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      toast.error(error);
    }
  };

  const teamGymnasticsHandler = (categoryItem) => {
    setProgramName(categoryItem?.item?.name);
    setProgramId(categoryItem?.item?.alias_id);
    setTeamsAliasId(categoryItem?.item?.id);
    setShowCMSEditModal(true);
    setPrevImage(categoryItem?.item?.image);
    setPrevDescription(categoryItem?.item?.description);
  };

  const deleteClassHandler = (categoryItem, programName, type) => {
    setShowDeleteModal(true);
    setCategoryItems(categoryItem);
    setPrograms(programName);
    setDeleteType(type);
  };

  const hideProgramHandler = async (categoryItem, index) => {
    setProgramHide((prevState) => {
      const temp = [...prevState];

      temp[index] = temp?.[index] === 1 ? 0 : 1;
      return temp;
    });
    const postData = {
      hidden: programHide?.[index] === 0 ? 1 : 0,
      [activeAccordion === 0 ? "programId" : "browseByAgeId"]: parseInt(
        categoryItem?.item?.id
      ),
    };

    if (activeAccordion === 0) {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + `/admin/hide_unhide_program`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response?.status === 200 || response?.status === 201) {
        toast.success("Program updated successfully");
      }
    } else {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + `/admin/hide_unhide_age`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response?.status === 200 || response?.status === 201) {
        toast.success("Program updated successfully");
      }
    }
  };
  return (
    <>
      {showDeleteModal && (
        <DeleteClassModal
          categoryItems={categoryItems}
          programs={programs}
          setShowDeleteModal={setShowDeleteModal}
          getManageDataFunction={getManageDataFunction}
          deleteType={deleteType}
          enrichmentClassHandler={enrichmentClassHandler}
          programId={programId}
          enrichmentCategoryId={enrichmentCategoryId}
          setEnrichemntCategoryId={setEnrichemntCategoryId}
        />
      )}
      {programClassEditModal && (
        <EditClassModal
          setProgramClassEditModal={setProgramClassEditModal}
          className={className}
          programId={programId}
          programName={programName}
          classId={classId}
          allClassData={allClassData}
          enrichmentManageClass={enrichmentManageClass}
          setEnrichmentManageClass={setEnrichmentManageClass}
          dummayManageProgramData={dummayManageProgramData}
          indexNumber={indexNumber}
          setIndexNumber={setIndexNumber}
          getManageDataFunction={getManageDataFunction}
          selectedClassDetails={selectedClassDetails}
          ModalType = {"EDIT"}
        />
      )}

      {showCMSEditModal && (
        <EditProgramModal
          setShowCMSEditModal={setShowCMSEditModal}
          programId={programId}
          programName={programName}
          prevImage={prevImage}
          prevDescription={prevDescription}
          activeAccordion={activeAccordion}
          getMasterProgramMenuData={getMasterProgramMenuData}
          setPrevImage={setPrevImage}
          setPrevDescription={setPrevDescription}
          teamsAliasId={teamsAliasId}
        />
      )}
      {showCampModal && (
        <CampCmsModal
          setProgramClassEditModal={setProgramClassEditModal}
          className={className}
          programId={programId}
          programName={programName}
          classId={classId}
          allClassData={allClassData}
          setShowCampModal={setShowCampModal}
          categoryItemForCamp={categoryItemForCamp}
          campClassDetails={campClassDetails}
        />
      )}

      {/* CHEER CAMP MODAL  */}

      {/* {loading && <LoaderComponent />} */}

      <div
        className="Adminpage__body"
        style={{ minHeight: "auto" }}
        id="tablePdfContent"
        ref={scrollContainerRef}
      >
        <table
          style={{
            borderCollapse: "collapse",
            width: "100%",
            height: "max-content",
          }}
        >
          <thead>
            <tr>
              {headerData.map((i, index) => (
                <th
                  style={{ minWidth: "200px" }}
                  key={index}
                  onClick={() => handleHeaderClick(i, index)}
                >
                  <span style={{ cursor: "pointer" }}>
                    {i.icon}
                    {i.name}
                  </span>
                </th>
              ))}
            </tr>
          </thead>

          {loadingState ? (
            <tbody>
              <tr
                style={{
                  width: "100%",
                  textAlign: "left",
                  paddingLeft: "5%",
                  height: "100%",
                }}
              >
                <td colSpan={headerData?.length}>
                  <p>Loading...</p>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {bodyData.length ? (
                bodyData?.map((categoryItem, ind) => {
                  return (
                    <tr
                      key={ind}
                      style={tableName === "Orders" ? { height: "300px" } : {}}
                    >
                      {categoryItem?.data?.map((text, index) => {
                        return (
                          <td key={index} style={{ minWidth: "200px" }}>
                            <span>{text}</span>
                          </td>
                        );
                      })}
                      <td>
                        {tableName === "Attendance" ? (
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={isPresentArray[ind]}
                              onChange={() => attendenceHandler(ind)}
                              style={{ cursor: "pointer" }}
                            />
                            <span className="slider round"></span>
                          </label>
                        ) : tableName === "Report" ||
                          tableName === "Orders" ||
                          tableName === "Notification" ? (
                          ""
                        ) : tableName === "Programs" ? (
                          <div style={{ display: "flex" }}>
                            {categoryItem?.data?.[1] !== "Teams" ? (
                              <button
                                onClick={() =>
                                  editCmsProgramHandler(categoryItem)
                                }
                              >
                                {" "}
                                <EditRowIcon />
                              </button>
                            ) : (
                              <button
                                onClick={() =>
                                  teamGymnasticsHandler(categoryItem)
                                }
                              >
                                {" "}
                                <EditRowIcon />
                              </button>
                            )}
                            {categoryItem?.data?.[1] !== "Teams" ? (
                              <div>
                                <button
                                  className="BrowseByProgramManegebtn"
                                  onClick={() =>
                                    manageClassesHandler(categoryItem)
                                  }
                                >
                                  Manage Classes
                                </button>

                                <button
                                  className="hide-btn-manage-class"
                                  onClick={() =>
                                    hideProgramHandler(categoryItem, ind)
                                  }
                                >
                                  <img
                                    src={
                                      programHide?.[ind] === 1 ? show : hideIcon
                                    }
                                    alt=""
                                  />
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : tableName === "Class's Program" ? (
                          <div style={{ display: "flex" }}>
                            <button
                              onClick={() => {
                                setProgramClassEditModal(true);
                                editClassProgramHandler(categoryItem);
                              }}
                            >
                              <EditRowIcon />
                            </button>
                            {isEnrichmentCombo !== "Enrichment Combos" ||
                            enrichmentManageClass ? (
                              <div>
                                <button
                                  className="hide-btn-manage-class"
                                  onClick={() => {
                                    setShowIcon(!showIcon);
                                    hideClassHandler(
                                      ind,
                                      categoryItem,
                                      isClassesShown?.[ind],
                                      programName
                                    );
                                  }}
                                >
                                  <img
                                    src={
                                      isClassesShown?.[ind] ? hideIcon : show
                                    }
                                    alt=""
                                  />
                                </button>
                                {campProgramName !== "Camp" && (
                                  <button
                                    className="hide-btn-manage-class"
                                    onClick={() =>
                                      deleteClassHandler(
                                        categoryItem,
                                        programName,
                                        "class"
                                      )
                                    }
                                  >
                                    <img src={deleteIcon} alt="" />
                                  </button>
                                )}
                              </div>
                            ) : (
                              !enrichmentManageClass && (
                                <div>
                                  <button
                                    className="BrowseByProgramManegebtn"
                                    onClick={() => {
                                      enrichmentClassHandler(categoryItem);
                                      setEnrichemntCategoryId(
                                        categoryItem?.data?.[0]
                                      );
                                    }}
                                  >
                                    Enrichment Classes
                                  </button>
                                  <button
                                    className="hide-btn-manage-class"
                                    onClick={() =>
                                      deleteClassHandler(
                                        categoryItem,
                                        programName,
                                        "category"
                                      )
                                    }
                                  >
                                    <img src={deleteIcon} alt="" />
                                  </button>
                                </div>
                              )
                            )}
                            {campProgramName == "Camp" && (
                              <button
                                className="adminbtn adminbtn-primary "
                                onClick={() => {
                                  setCategoryForCamp(categoryItem);
                                  setShowCampModal(true);
                                  campClassHanlder(categoryItem);
                                }}
                              >
                                Click
                              </button>
                            )}
                          </div>
                        ) : (
                          <div className="adminbutton__group">
                            <button
                              className=""
                              onClick={() => {
                                categoryItem.onEditPress();
                              }}
                            >
                              <EditRowIcon />
                            </button>
                            <button
                              className=""
                              id="btnDelete"
                              onClick={() => {
                                categoryItem.onDeletePress();
                              }}
                            >
                              <DeleteRowIcon />
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={headerData.length} className="no-data">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>

      <DeleteUser
        data={categoryData}
        openDeleteModal={deleteCategoryModal}
        setDeleteModal={setDeleteCategoryModal}
      />

      {tableName === "Report" || tableName === "Programs" ? (
        " "
      ) : (
        <div className="pagination-container">
          <ReactPaginate
            pageCount={Math.ceil(totalPages)}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={handlePageChange}
            initialPage={activePage}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
          {tableName === "Class's Program" ? (
            ""
          ) : (
            <div className="sub-pagination-container">
              <p style={{ fontSize: "16px", flex: "none" }}>
                Showing Data [{pageLimit * (currentPage + 1) + 1 - pageLimit}-{" "}
                {totalCount < pageLimit * (currentPage + 1)
                  ? totalCount
                  : pageLimit * (currentPage + 1)}{" "}
                of {totalCount}]
              </p>
              <select
                onChange={(e) => {
                  setPageLimit(e.target.value);
                  // if(tableName==="Attendance"){
                  //   handleSearch();
                  // }
                }}
                className="adminform__select"
                style={{ minWidth: "50px" }}
              >
                <option disabled>Select Rows</option>
                <option value={10}>10</option>
                <option value={5}>5</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
              </select>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default TableNew;
