import axios from "axios";
import { GET_CLASSES } from "../adminQueries/Classes";
import { toast } from "react-toastify";
import store from "../../store/store";
import { openSessionModal } from "../../reducers/sessionHandler";

export async function getClass(token, sortKey, page, formData, pageLimit) {
  // Convert formData to a format suitable for API consumption
  const variable = {};

  formData.forEach((item) => {
    if (item.selectUser === "classId") {
      variable[item.selectUser] = parseInt(item.name);
    } else {
      variable[item.selectUser] = item.name;
    }
  });
  try {
    const variables = {
      pageInfo: {
        isAsc: sortKey?.isAsc,
        limit: parseInt(pageLimit),
        page: page,
        sort: sortKey?.sortId === null ? null : sortKey?.sortId?.toString(),
      },
      ...variable,
    };

    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/graphql",
      {
        query: GET_CLASSES,
        variables: variables,
      },
      {
        headers: {
          Authorization: `Bearer `,
        },
      }
    );

    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }

    return response?.data?.data?.getClassesForAdmin;
  } catch (error) {
    console.error("Error fetching program details from GraphQL", error);
    throw error;
  }
}

export const getClassesNew = async (
  token,
  isEnded,
  page,
  limit,
  className,
  rollSheetSearchState
) => {
  try {
    const data = {
      isEnded: isEnded,
      page: page,
      limit: 5,
      ...(rollSheetSearchState && {
        [rollSheetSearchState]:
          rollSheetSearchState === "id" ? parseInt(className) : className,
      }),
    };
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + `/admin/getclass`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data?.data;
  } catch (error) {
    console.error(error);
  }
};

export const getClassesByWeekdays = async (
  weekdays,
  token,
  isEnded,
  page,
  limit,
  className,
  rollSheetSearchState
) => {
  const data = {
    days: weekdays,
    isEnded: isEnded,
    limit: 5,
    page: page,
    ...(rollSheetSearchState && {
      [rollSheetSearchState]:
        rollSheetSearchState === "id" ? parseInt(className) : className,
    }),
  };

  const response = await axios.post(
    process.env.REACT_APP_BASE_URL + `/admin/getweekdayclass`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
  if (
    response.data.errors &&
    response.data.errors[0]?.message === "Unauthorised."
  ) {
    setTimeout(() => {
      store?.dispatch(openSessionModal(true));
    }, 1000);
  }
  return response?.data?.data;
};
// CReate Class

export async function createClassAPI(token, classDetails) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/admin/class/create",
      classDetails,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      return { error: error.response.data.error };
    } else {
      return { error: "An error occurred" };
    }
  }
}

// create Week Class APi
export async function createWeekClassAPI(token, classDetails) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/admin/weekly-class/create",
      classDetails,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      return { error: error.response.data.error };
    } else {
      return { error: "An error occurred" };
    }
  }
}

// get Student by class iD

export async function getStudentByClassID(token, classId, isCamp) {
  let Camp = isCamp == "Monthly" ? false : true;

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/graphql`,
      {
        query: `
          query GetEnrolledStudentsFromClassId($pageInfo: PaginationInfo, $classId: Int! , $isCamp : Boolean) {
            getEnrolledStudentsFromClassId(pageInfo: $pageInfo, classId: $classId, isCamp : $isCamp ) {
              data {
                id
                first_name
                last_name
                age
                proRatedFee
                dob
              }
              totalCount
              totalPages
              limit
              currPage
            }
          }
        `,
        variables: {
          pageInfo: {
            isAsc: false,
            page: 0,
            sort: null,
            limit: 100,
          },
          classId: classId,
          isCamp: Camp,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data?.data?.getEnrolledStudentsFromClassId;
  } catch (error) {
    console.error("Error fetching enrolled students from GraphQL", error);
    throw error;
  }
}

// Edit CLass  API

export async function editClassAPI(token, classID, formData) {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/admin/class/update/${classID}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      return { error: error.response.data.error };
    } else {
      return { error: "An error occurred" };
    }
  }
}

// Delete ::

export async function deleteClassAPI(token, classID, data) {
  let isCamp = data?.period !== "Monthly" ? true : false;
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/admin/class/delete/${classID}/${isCamp}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

// CART API HANDLES :
export async function studentAddToCartApiFunction(token, data) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/student/add-to-cart`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.error, {
      toastId: "error",
    });
    console.error(error);
  }
}

// Delete From The Cart

export async function deleteDataFromTheCart(token, classID) {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/admin/class/delete/${classID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (
      response.data.errors &&
      response.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

//half day full day camp create api

export const createCampHalfFullDayWeeklyClass = async (token, postData) => {
  try {
    const weeklyClass = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/admin/weekly-class/create`,
      postData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (
      weeklyClass.data.errors &&
      weeklyClass.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return weeklyClass;
  } catch (error) {}
};

export const editCampHalfFullDayWeeklyClass = async (id, token, data) => {
  try {
    const weeklyEditClass = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/admin/weekly-class/update/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (
      weeklyEditClass.data.errors &&
      weeklyEditClass.data.errors[0]?.message === "Unauthorised."
    ) {
      setTimeout(() => {
        store?.dispatch(openSessionModal(true));
      }, 1000);
    }
    return weeklyEditClass;
  } catch (error) {
    return error;
  }
};

export const deleteClassFromStudent = async (
  studentId,
  classScheduleId,
  period,
  token
) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/delete-enrollment/${studentId}/${classScheduleId}/${period}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting class:", error);
    throw error;
  }
};
