import React, { useState } from "react";

import Header from "../components/Header";
import NavigationComponent from "../components/NavigationComponent";
import { debounce } from "lodash";
import Table from "../components/TableComponent/Table";
import { useEffect } from "react";
import { getClass } from "../adminApi/classApi";
import { useSelector } from "react-redux";
import AddNewClass from "../components/Classess/AddNewClass";
import DeleteModal from "../components/UserModals/DeleteModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  customStyles,
  dateFormatter,
  getCurrentDateYYMMDD,
  timeFormatter,
} from "../adminUtility";
import Select from "react-select";
import { ReactComponent as SearchIcon } from "../assets/images/icons/search.svg";

import {
  getClassForDropdown,
  getWarningForClass,
} from "../adminApi/addClassList";
import ShowTeachers from "../components/Classess/ShowTeachers";
import {
  classTableHeader,
  subHeaderClassTable,
} from "../tableHeaderComponents";

const Classes = () => {
  // const [openStudent, setOpenStudent] = useState(false);
  const [addNewClasses, setAddNewClasses] = useState(false);
  const [getClassApiData, setGetClassApiData] = useState();
  const [makeUpClassStatus, setMakeUpStatus] = useState();
  const [sortKey, setSortKey] = useState({
    sortId: null,
    isAsc: false,
  });
  const [selectedClassId, setSelectedClassId] = useState();
  const [editClassID, setEditClassID] = useState();
  const [selectedStudentID, setSelectedStudentID] = useState();
  const [totalPages, setTotalPages] = useState();
  const [editClass, setEditClass] = useState(false);
  const [deleteClass, setDeleteClass] = useState();
  const page = useSelector((state) => state?.adminSlice?.adminModalContent);
  const [classData, setClassData] = useState();
  const [formData, setFormData] = useState([]);
  const [temp, setTemp] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [deleteMessage, setDeleteMessage] = useState();
  const [classListData, setClassList] = useState();
  const [editClassData, setEditClassData] = useState();
  // Sort
  const [pageLimit, setPageLimit] = useState("10");
  const [loadingState, setLoadingState] = useState(false);
  const role = useSelector((state) => state?.userRole?.RoleId);
  const [showTeacherModal, setShowTeacherModal] = useState(false);
  const [hoveredClassData, setHoveredClassData] = useState(null);
  const [multiDeleteClass, setMultiDeleteClass] = useState([]);
  const [isClassEdited, setIsClassEdited] = useState(false);

  // TABLE DATA
  const getCLassData = async () => {
    try {
      setLoadingState(true);
      const token = localStorage.getItem("access_token");
      const getData = await getClass(
        token,
        // isClassEdited
        //   ? {
        //       sortId: null,
        //       isAsc: false,
        //     }
        //   : sortKey,
        sortKey,
        isClassEdited ? (page == 0 ? 0 : page) : page,
        // isClassEdited ? [] : formData,
        formData,
        pageLimit
      );
      setTotalPages(getData?.totalPages);
      setCurrentPage(getData?.currPage);
      setTotalCount(getData?.totalCount);
      setGetClassApiData(getData?.data);
      if (getData) {
        const modifiedData = getData?.data.map((classItem, index) => {
          return {
            data: [
              classItem?.id,
              classItem?.season_name,
              classItem?.name,
              classItem?.category_name,
              classItem?.is_combo === true
                ? "COMBO CLASS"
                : classItem?.is_combo === false &&
                  (classItem?.halfDay?.length === 0 ||
                    classItem?.halfDay === null) &&
                  (classItem?.fullDay?.length === 0 ||
                    classItem?.fullDay === null)
                ? "NORMAL CLASS"
                : "CAMP",
              dateFormatter(classItem?.start_date),
              "$ " + classItem?.tuition,
              classItem?.min_age + " " + "Months",
              classItem?.max_age + " " + "Months",
              classItem?.max_strength > 0 ? classItem?.max_strength : "-",
              classItem?.website,

              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setHoveredClassData(classItem);
                  setShowTeacherModal(true);
                }}
              >
                <button className="adminbtn adminbtn-primary">View</button>
              </div>,
            ],
            item: classItem,
            tableType: "Classes",
            onDeletePress: async () => {
              try {
                const response = await getWarningForClass(
                  token,
                  classItem?.id,
                  classItem
                );
                setDeleteMessage(response?.data);
              } catch (error) {}
              setEditClassID(classItem);
              setDeleteClass(true);
            },

            onEditPress: () => {
              setEditClass(true);
              // setEditClassID(classItem);
              setEditClassData(classItem);
            },
          };
        });

        setClassData(modifiedData);
        setLoadingState(false);
      } else {
        setLoadingState(false);
      }
    } catch (error) {
      setLoadingState(false);
    }
  };

  const handleSearch = () => {
    getCLassData(formData);
  };

  useEffect(() => {
    getCLassData();
  }, [sortKey, page, editClassID, pageLimit, isClassEdited]);

  const debouncedFetchData = debounce(getCLassData, 2000);

  useEffect(() => {
    const shouldFetchData = formData.some((data) => !data?.name);
    if (shouldFetchData) {
      const timeoutId = setTimeout(() => {
        debouncedFetchData(getCLassData);
      }, 900);

      return () => clearTimeout(timeoutId);
    }
  }, [formData]);

  const handleAddSearch = () => {
    setTemp((prevTemp) => prevTemp + 1);
  };

  const handleAdditionalFieldsChange = (index, key, value) => {
    const updatedData = [...formData];
    while (updatedData.length <= index) {
      updatedData.push({ selectUser: "", name: "" });
    }
    if (value !== updatedData[index][key]) {
      updatedData[index]["name"] = "";
    }
    updatedData[index][key] = value;

    setFormData(updatedData);
    getCLassData(formData);
  };

  const handleDateChange = (index, key, value) => {
    // Check if the value is not null or undefined
    if (value) {
      const formattedDate = value
        ?.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/\//g, "-");

      const updatedData = [...formData];

      while (updatedData.length <= index) {
        updatedData.push({ selectUser: "", name: "" });
      }

      updatedData[index][key] = getCurrentDateYYMMDD(formattedDate);
      setFormData(updatedData);
      getCLassData(formData);
    } else {
      const updatedData = [...formData];
      updatedData[index][key] = "";
      setFormData(updatedData);
      getCLassData(formData);
    }
  };

  const renderAdditionalFields = () => {
    const additionalFields = [];

    const classOptions = [
      { value: "", label: "Select Class" },
      ...(Array.isArray(classListData)
        ? classListData
            .filter((val) => val?.name.trim() !== "")
            .map((val) => ({
              show: val?.name,
              value: val.name,
              label: `${val?.name} ${
                val?.schedules?.length > 0 ? ", (" : ""
              }${val?.schedules
                ?.map((el) => {
                  if (el?.weekday && el?.start_time && el?.end_time) {
                    return `${el.weekday.substring(0, 3)} ${timeFormatter(
                      el.start_time
                    )} - ${timeFormatter(el.end_time)}`;
                  }
                  return null;
                })
                .filter(Boolean)
                .join(", ")} ${val?.schedules?.length > 0 ? ")" : ""}`,
            }))
        : []),
    ];

    for (let i = 0; i < temp; i++) {
      additionalFields.push(
        <React.Fragment key={i}>
          <div style={{ display: "flex", gap: "4px" }}>
            <div
              key={`selectUser_${i}`}
              className="adminform__group"
              style={{ minWidth: "150px" }}
            >
              <select
                style={{ minWidth: "150px" }}
                name={`selectUser_${i}`}
                id={`selectUser_${i}`}
                className="adminform__select"
                onChange={(e) =>
                  handleAdditionalFieldsChange(i, "selectUser", e.target.value)
                }
                value={formData[i]?.selectUser}
              >
                <option value="empty" selected disabled>
                  Search
                </option>
                <option value="season">Season</option>
                <option value="className">Class Name</option>
                <option value="category">Category</option>
                <option value="classId">Class Id</option>
                <option value="startDate">Start Date</option>
                <option value="tuition">Tuition</option>
                <option value="maxAge">Max Age</option>
                <option value="minAge">Min Age</option>
              </select>
            </div>

            <div key={`input_${i}`} className="adminform__group">
              {formData[i]?.selectUser === "className" ? (
                <Select
                  styles={customStyles}
                  options={classOptions}
                  onChange={(selectedOption) => {
                    handleAdditionalFieldsChange(
                      i,
                      "name",
                      selectedOption.value
                    );
                  }}
                  value={
                    formData[i]?.name
                      ? { value: formData[i]?.name, label: formData[i]?.name }
                      : null
                  }
                  placeholder={"Search here"}
                />
              ) : formData[i]?.selectUser === "startDate" ? (
                <DatePicker
                  className="adminform__input"
                  selected={
                    formData[i]?.name ? new Date(formData[i]?.name) : null
                  }
                  onChange={(date) => handleDateChange(i, "name", date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="MM/DD/YYYY"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  name={`name_${i}`}
                />
              ) : formData[i]?.selectUser === "tuition" ? (
                <input
                  type="number"
                  disabled={formData?.length > 0 ? false : true}
                  className="adminform__input"
                  placeholder="Search here"
                  name={`name_${i}`}
                  onChange={(e) =>
                    handleAdditionalFieldsChange(i, "name", e.target.value)
                  }
                  value={formData[i]?.name}
                />
              ) : (
                <input
                  type="text"
                  disabled={formData?.length > 0 ? false : true}
                  className="adminform__input"
                  placeholder="Search here"
                  name={`name_${i}`}
                  onChange={(e) =>
                    handleAdditionalFieldsChange(i, "name", e.target.value)
                  }
                  value={formData[i]?.name}
                />
              )}
            </div>
          </div>
        </React.Fragment>
      );
    }
    return additionalFields;
  };

  const handleCancelAdditionalFields = (index) => {
    setTemp((prevTemp) => {
      return prevTemp > 1 ? prevTemp - 1 : prevTemp;
    });

    setFormData((prevSearchParams) => {
      const newSearchParams = [...prevSearchParams];
      newSearchParams[index] = { selectUser: "", name: "" };
      const filteredSearchParams = newSearchParams.filter(
        (item) => item.selectUser !== "" || item.name !== ""
      );
      return filteredSearchParams;
    });
  };

  // Api for classList for sorted Dropdowns
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("access_token");
      try {
        const response = await getClassForDropdown(token, page, "2");
        setClassList(response?.data);
      } catch (error) {}
    };
    fetchData();
  }, []);

  const handleCheckboxChange = (id) => {
    setMultiDeleteClass((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  useEffect(() => {
    if (sortKey?.sortId !== null) {
      setIsClassEdited(false);
    }

    if (page !== 0) {
      setIsClassEdited(false);
    }
  }, [sortKey, isClassEdited]);

  return (
    <>
      <div className="container--component">
        <div className="container--grid">
          <NavigationComponent />

          <div className="Adminpage">
            <Header
              title={makeUpClassStatus ? "Classes >> Makeup Trial" : "Classes"}
              subTitle={makeUpClassStatus ? "" : "(Manage Available Classes)"}
            />

            <section className="Adminpage__section">
              <h2 className="Adminpage__title">Search by:</h2>

              <div className="Adminpage__head">
                <div className="Adminpage__filter">
                  {renderAdditionalFields()}

                  {temp <= 1 ? (
                    ""
                  ) : (
                    <button
                      type="button"
                      className="adminform__cancel-button"
                      onClick={() => handleCancelAdditionalFields(1)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  )}

                  <div
                    className=""
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className={
                        temp !== 2 ? "adminbtn adminbtn-light" : "adminbtn"
                      }
                      onClick={handleAddSearch}
                      disabled={temp === 2 ? true : false}
                      style={{
                        display: temp == 2 ? "none" : "block",
                        marginTop: "10px",
                      }}
                    >
                      and
                    </button>
                    <button
                      className="adminbtn adminbtn-primary"
                      onClick={handleSearch}
                    >
                      <SearchIcon />
                      Search
                    </button>
                  </div>
                </div>

                <button
                  className="adminbtn adminbtn-light export_btn_attendence"
                  id="btnCategory"
                  onClick={async () => {
                    setAddNewClasses(true);
                  }}
                >
                  + Add New Class
                </button>
              </div>

              <Table
                loadingState={loadingState}
                headerData={classTableHeader}
                bodyData={classData}
                subHeader={subHeaderClassTable}
                setSortKey={setSortKey}
                tableType={makeUpClassStatus ? "MAKEUP" : "Classes"}
                totalPages={totalPages}
                setPageLimit={setPageLimit}
                pageLimit={pageLimit}
                totalCount={totalCount}
                currentPage={currentPage}
                setSelectedClassId={setSelectedClassId}
                selectedClassId={selectedClassId}
                setMakeUpStatus={setMakeUpStatus}
                setSelectedStudentID={setSelectedStudentID}
                selectedStudentID={selectedStudentID}
                formData={formData}
                setMultiDeleteClass={setMultiDeleteClass}
                handleCheckboxChange={handleCheckboxChange}
                setIsClassEdited={setIsClassEdited}
                isClassEdited={isClassEdited}
              />
            </section>
          </div>
        </div>
      </div>

      {addNewClasses ? (
        <AddNewClass
          setIsClassEdited={setIsClassEdited}
          searchedParam={formData}
          setSearchedParam={setFormData}
          setAddNewClasses={setAddNewClasses}
          openAddNewClass={addNewClasses}
          type="Add"
          fetchData={getCLassData}
          getClassData={getClassApiData}
          setSortKey={setSortKey}
        />
      ) : null}

      {/* EDIT CLASS  */}
      {editClass ? (
        <AddNewClass
          setIsClassEdited={setIsClassEdited}
          searchedParam={formData}
          setSearchedParam={setFormData}
          setAddNewClasses={setEditClass}
          openAddNewClass={editClass}
          type="Edit"
          data={editClassData}
          fetchData={getCLassData}
          getClassData={getClassApiData}
          sortKey={sortKey}
          setSortKey={setSortKey}
        />
      ) : null}

      {/* DELETE CLASS  */}

      {deleteClass && editClassID ? (
        <DeleteModal
          openDeleteModal={deleteClass}
          setDeleteModal={setDeleteClass}
          data={editClassID}
          fetchData={getCLassData}
          type="Class"
          deleteMessage={deleteMessage}
        />
      ) : null}

      {/* Show teacher Details  */}
      {showTeacherModal && (
        <ShowTeachers
          showTeacherModal={showTeacherModal}
          setShowTeacherModal={setShowTeacherModal}
          hoveredClassData={hoveredClassData}
          type="Class"
        />
      )}
    </>
  );
};

export default Classes;
