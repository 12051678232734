import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import aboutUsBanner from "../../../parent-assets/images/home-banner.jpg";
import browseByAge1 from "../../../parent-assets/images/Browse-by-age-boxes/0-12.png";
import browseByAge2 from "../../../parent-assets/images/Browse-by-age-boxes/12-24.png";
import browseByAge4 from "../../../parent-assets/images/Browse-by-age-boxes/2.png";
import browseByAge5 from "../../../parent-assets/images/Browse-by-age-boxes/3.png";
import browseByAge6 from "../../../parent-assets/images/Browse-by-age-boxes/4.png";
import browseByAge7 from "../../../parent-assets/images/Browse-by-age-boxes/5.png";
import browseByAge8 from "../../../parent-assets/images/Browse-by-age-boxes/6.png";
import browseByAge9 from "../../../parent-assets/images/Browse-by-age-boxes/7.png";
import { getAgeProgramHeaderImage } from "../../../../Admin/adminApi/ageProgramHeaderApi";
import AboutUsShimmer from "../../../../Admin/components/ShimmerUi/AboutUsShimmer";
import { getCmsAge } from "../../../../Admin/adminApi/program";

function BrowseByAgeOptions() {


  
  const [headerImage, setHeaderImage] = useState();
  const [description, setDescription] = useState();
  const navigate = useNavigate();
  const [showShimmer, setShowShimmer] = useState(false);
  const token = localStorage.getItem("access_token");
  const [ageData, setAgeData] = useState();
  const getBrowsByAgeDataCMS = async () => {
    try {
      const response = await getCmsAge(token);
      setAgeData(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getHeadersImage();
    getBrowsByAgeDataCMS();
  }, []);

  const getHeadersImage = async () => {
    try {
      setShowShimmer(true);
      const response = await getAgeProgramHeaderImage(token);
      setHeaderImage(response?.data?.data?.[0]?.browse_by_age_header);
      setDescription(response?.data?.data?.[0]?.browse_by_age_desc);
      setShowShimmer(false);
    } catch (error) {
      setShowShimmer(false);
      return error;
    }
  };

  const programs = [
    {
      age_range: "0-12",
      link: "/browse-by-programs/0-12/10?browse-by=false",
      imgSrc: browseByAge1,
    },
    {
      age_range: "12-24",
      link: "/browse-by-programs/12-24/11?browse-by=false",
      imgSrc: browseByAge2,
    },
    {
      age_range: "2",
      link: "/browse-by-programs/2/12?browse-by=false",
      imgSrc: browseByAge4,
    },
    {
      age_range: "3",
      link: "/browse-by-programs/3/13?browse-by=false",
      imgSrc: browseByAge5,
    },
    {
      age_range: "4",
      link: "/browse-by-programs/4/14?browse-by=false",
      imgSrc: browseByAge6,
    },
    {
      age_range: "5",
      link: "/browse-by-programs/5/15?browse-by=false",
      imgSrc: browseByAge7,
    },
    {
      age_range: "6",
      link: "/browse-by-programs/6/16?browse-by=false",
      imgSrc: browseByAge8,
    },
    {
      age_range: "7+",
      link: "/browse-by-programs/7+/17?browse-by=false",
      imgSrc: browseByAge9,
    },
  ];

  return (
    <>
      {showShimmer ? (
        <AboutUsShimmer component={"Birthday"} />
      ) : (
        <div className="contentwraper">
          <section className="siteBanner" style={{ background: "#EA519C" }}>
            <div className="siteBanner__overlay">
              <img
                className="siteBanner__img"
                src={headerImage ? headerImage : aboutUsBanner}
                alt=""
              />
            </div>
          </section>
          <section className="breadcrumbSec">
            <div className="container">
              <div className="breadcrumb">
                <div className="breadcrumb__item">
                  <Link
                    className="breadcrumb__link"
                    onClick={() => navigate(-1)}
                  >
                    Back to results
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <section className="facilitiesSec">
            <div className="container">
              <h1 className="title title--extrabold">Program By Age Options</h1>
              <div className="shortText">
                <p>
                  {description
                    ? description
                    : `We offer a wide variety of age-appropriate classes at Gymtime, including gymnastics, art, music, cooking, and more, all designed to nurture growth and development across all age groups. Our programs are carefully crafted to ensure that children of all ages can enjoy and benefit from a diverse range of activities, supporting their physical, creative, and personal growth to help them reach their full potential.`}
                </p>
              </div>
              <div className="maxwith1100">
                <ul className="commonList commonList--threecolumn">
                  {programs.map((program, index) => {
                    const apiProgram = ageData?.find(
                      (apiProg) => apiProg.age_range === program.age_range
                    );
                    const isActiveTrue = apiProgram && apiProgram.is_active;
                    // Render the program card only if is_active_true is not true
                    if (isActiveTrue) {
                      return (
                        <li key={index} className="commonList__item">
                          <Link className="commonList__card" to={program.link}>
                            <img
                              className="commonList__card__img"
                              src={program.imgSrc}
                              alt=""
                            />
                          </Link>
                        </li>
                      );
                    } else {
                      return null;
                    }
                  })}
                </ul>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default BrowseByAgeOptions;
