import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { setSuccessModalContent } from "../../../reducers/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../reducers/loadingSlice";
import { toast } from "react-toastify";
import { ReactComponent as GymtimeLogo } from "../../parent-assets/images/gymtime_logo_2024.svg";
import { openLoginModal } from "../../../reducers/sessionHandler";
import LoaderComponent from "../../../Admin/components/LoaderComponent";

function useOutsideAlerter(ref, setModal) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        ref.current.className === "modal__content"
      ) {
        setModal(false);
      } else {
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function SignupModal({ modal, setModal, setSignupStatus, setSuccessModal }) {
  const navigate = useNavigate();
  const loader = useSelector((state) => state);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const [signUpformData, setSignupFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    createPassword: "",
    termsAndConditions: false,
    authForm: false,
  });
  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    email: "",
    createPassword: "",
    termsAndConditions: "",
    authForm: "",
  });

  const validateInputEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,66}$/;
    // const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;

    if (!emailPattern.test(email)) {
      return "Invalid email address";
    }
    // if (!passwordPattern.test(password)) {
    //   return "Password must contain at least one digit, one lowercase and one uppercase letter, and be at least 6 characters long.";
    // }
    return null;
  };

  const validateInputPassword = (password) => {
    const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;

    if (!passwordPattern.test(password)) {
      return "Password must contain at least one digit, one lowercase and one uppercase letter, and be at least 6 characters long.";
    }
    return null;
  };

  const validateForm = () => {
    let hasErrors = false;
    let firstnameError = "";
    let lastnameError = "";
    let emailError = validateInputEmail(signUpformData.email);
    let passwordError = validateInputPassword(signUpformData.createPassword);
    let termsAndConditionsError = "";
    let authFormError = "";

    // Validate first name
    if (!signUpformData.firstname) {
      firstnameError = "Enter first name";
      hasErrors = true;
    } else if (!/^[a-zA-Z]+$/.test(signUpformData.firstname)) {
      firstnameError = "First name should not contain numbers";
      hasErrors = true;
    }

    // Validate last name
    if (!signUpformData.lastname) {
      lastnameError = "Enter last name";
      hasErrors = true;
    } else if (!/^[a-zA-Z]+$/.test(signUpformData.lastname)) {
      lastnameError = "Last name should not contain numbers";
      hasErrors = true;
    }

    // Validate email
    if (!signUpformData.email) {
      emailError = "Enter the email";
      hasErrors = true;
    }

    // Validate password
    if (!signUpformData.createPassword) {
      passwordError = "Enter the password";
      hasErrors = true;
    }

    // Validate terms and conditions
    if (!signUpformData.termsAndConditions) {
      termsAndConditionsError = "You must agree to the terms and conditions";
      hasErrors = true;
    }

    // Validate authorization form
    // if (!signUpformData.authForm) {
    //   authFormError =
    //     "Accept the Credit Card Authorization form for Gymtime to proceed!";
    //   hasErrors = true;
    // }

    setErrors({
      firstname: firstnameError,
      lastname: lastnameError,
      email: emailError,
      createPassword: passwordError,
      termsAndConditions: termsAndConditionsError,
      authFormError: authFormError,
    });

    return !hasErrors;
  };

  const closeSignupModal = () => {
    setModal({ key: null, flag: false });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const emailError = validateInputEmail(signUpformData.email);
   
    setErrors({ ...errors, email: emailError });
    const passwordError = validateInputPassword(signUpformData.createPassword)
   
    setErrors({
      ...errors,
      createPassword: passwordError,
    });
    if(emailError || passwordError){
      return;
    }
    dispatch(setLoading(true));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/signup`,
        {
          first_name: signUpformData.firstname,
          last_name: signUpformData.lastname,
          email: signUpformData.email,
          password: signUpformData.createPassword,
          authForm: signUpformData.authForm,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setTimeout(() => {
        dispatch(setLoading(false));
      }, 3000);

      setSuccessModal(true);
      dispatch(openLoginModal(false));
      localStorage.setItem("access_token", response?.data?.data?.access_token);
      closeSignupModal();
      dispatch(setSuccessModalContent(response?.data?.data));
      setSignupStatus(true);
      navigate("/");
    } catch (error) {
      toast.error(error?.response?.data?.error, {
        toastId: "error",
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setModal);

  console.log("ERRORR", errors);

  useEffect(() => {
    // Clear errors conditionally based on form data
    const updatedErrors = {};

    if (signUpformData.firstname) {
      updatedErrors.firstname = "";
    }
    if (signUpformData.lastname) {
      updatedErrors.lastname = "";
    }
    if (signUpformData.email) {
      updatedErrors.email = "";
    }
    if (signUpformData.createPassword) {
      updatedErrors.createPassword = "";
    }
    if (signUpformData.termsAndConditions) {
      updatedErrors.termsAndConditions = "";
    }
    if (signUpformData.authForm) {
      updatedErrors.authFormError = "";
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      ...updatedErrors,
    }));
  }, [signUpformData]);

  const checkboxStyles = {
    padding: " 33px",
    width: "15px",
    height: "61px",
    fontWeight: "500",
  };

  return (
    <>
      <div
        className="modal modal--loginModal modal--open  "
        id="SignupModal"
        role="dialog"
      >
        <div className="modal__dialog modal__dialog--lg">
          <div ref={wrapperRef} className="modal__content login_modal_content">
            {loader?.loading?.loading && <LoaderComponent />}

            <div className="loginWrap">
              <div className="loginWrap__item">
                <div className="loginWrap__header">
                  <p
                    className="backbtn login-cross"
                    style={{ marginLeft: "96%" }}
                    onClick={() => {
                      setModal(false);
                      dispatch(openLoginModal(false));
                    }}
                  >
                    {" "}
                    X
                  </p>
                  <div className="loginWrap__branding">
                    <button
                      className="backbtn"
                      onClick={() => setModal({ key: "login", flag: true })}
                    >
                      <svg
                        width="9"
                        height="15"
                        viewBox="0 0 9 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.08357 1.8115L6.85403 0.588867L0.0224609 7.42734L6.86094 14.2658L8.08357 13.0432L2.46773 7.42734L8.08357 1.8115Z"
                          fill="#8692A6"
                        />
                      </svg>
                      Back
                    </button>
                    <div className="logo">
                      <Link
                        onClick={() => setModal({ key: null, flag: false })}
                      >
                        <GymtimeLogo />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="loginWrap__form">
                  <div className="loginWrap__titlebox">
                    <h2 className="title">Register Individual Account!</h2>
                    <p className="para">
                      For the purpose of industry regulation, your details are
                      required.
                    </p>
                  </div>
                  <form className="form" onSubmit={handleSubmit}>
                    <div className="form__group">
                      <label className="form__label label">
                        First name
                        <span className="form__label__asterisks important_field">
                          *
                        </span>
                      </label>
                      <input
                        className="form__input"
                        id="firstname"
                        type="text"
                        placeholder="Enter your first name"
                        value={signUpformData.firstname}
                        onChange={(e) =>
                          setSignupFormData({
                            ...signUpformData,
                            firstname: e.target.value,
                          })
                        }
                      />
                      {errors.firstname && (
                        <span
                          className="error"
                          style={{ color: "red", fontSize: "0.8rem" }}
                        >
                          {errors.firstname}
                        </span>
                      )}
                    </div>
                    <div className="form__group">
                      <label className="form__label label  ">
                        Last name
                        <span className="form__label__asterisks  important_field">
                          *
                        </span>
                      </label>
                      <input
                        className="form__input"
                        id="lastname"
                        type="text"
                        placeholder="Enter your last name"
                        value={signUpformData.lastname}
                        onChange={(e) =>
                          setSignupFormData({
                            ...signUpformData,
                            lastname: e.target.value,
                          })
                        }
                      />
                      {errors.lastname && (
                        <span
                          className="error"
                          style={{ color: "red", fontSize: "0.8rem" }}
                        >
                          {errors.lastname}
                        </span>
                      )}
                    </div>
                    <div className="form__group">
                      <label className="form__label label">
                        Email address
                        <span className="form__label__asterisks important_field">
                          *
                        </span>
                      </label>
                      <input
                        className="form__input"
                        id="email"
                        type="email"
                        placeholder="Enter email address"
                        value={signUpformData.email}
                        onChange={(e) => {
                          // const emailError = validateInput(
                          //   e.target.value,
                          //   "email"
                          // );
                          // setErrors({ ...errors, email: emailError });
                          setSignupFormData({
                            ...signUpformData,
                            email: e.target.value,
                          });
                        }}
                      />
                      {errors.email && (
                        <span
                          className="error"
                          style={{ color: "red", fontSize: "0.8rem" }}
                        >
                          {errors.email}
                        </span>
                      )}
                    </div>
                    <div className="form__group">
                      <label className="form__label label">
                        Create password
                        <span className="form__label__asterisks important_field">
                          *
                        </span>
                      </label>
                      <div className="form__paswordfield">
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          className="form__input"
                          placeholder="Create password"
                          value={signUpformData.createPassword}
                          onChange={(e) => {
                            // const passwordError = validateInput(
                            //   e.target.value,
                            //   "password"
                            // );
                            // setErrors({
                            //   ...errors,
                            //   createPassword: passwordError,
                            // });
                            setSignupFormData({
                              ...signUpformData,
                              createPassword: e.target.value,
                            });
                          }}
                        />
                        {errors.createPassword && (
                          <span className="error" style={{ color: "red" }}>
                            {errors.createPassword}
                          </span>
                        )}
                        <button
                          type="button"
                          className="btn"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          {showPassword ? "Hide" : "Show"}
                        </button>
                      </div>
                    </div>
                    <div
                      className="form__group"
                      style={{ marginBottom: "1rem" }}
                    >
                      <div
                        className="form__checkbox"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                      >
                        <div className="form__checkbox__option">
                          <input
                            type="checkbox"
                            id="termsandconditions"
                            // className="form__checkbox__input form__checkbox__input--withoutlabel"
                            checked={signUpformData.termsAndConditions}
                            onChange={(e) =>
                              setSignupFormData({
                                ...signUpformData,
                                termsAndConditions: e.target.checked,
                              })
                            }
                          />
                          <div className="form__checkbox__labelText">
                            &nbsp;&nbsp;
                            <Link
                              to="/terms"
                              target="_blank"
                              style={{
                                textDecoration: "none",
                                fontWeight: "500",
                              }}
                            >
                              <span className="important_field">*</span>
                              &nbsp; I agree to terms & conditions
                            </Link>
                          </div>
                          &nbsp;
                        </div>

                        <div>
                          {errors.termsAndConditions && (
                            <span
                              className="error"
                              style={{ color: "red", fontSize: "0.8rem" }}
                            >
                              {errors.termsAndConditions}
                            </span>
                          )}
                        </div>
                      </div>

                      {/* AUTHOIRZATION FORM  */}
                      {/* <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignContent: "center",
                          marginTop: "-20px",
                          marginBottom: "1.5rem",
                        }}
                      >
                        <input
                          type="checkbox"
                          id="authForm"
                          name="authFrorm"
                          onChange={(e) =>
                            setSignupFormData({
                              ...signUpformData,
                              authForm: e.target.checked,
                            })
                          }
                          className="authForm_checkbox"
                          style={{
                            checkboxStyles,
                          }}
                        />
                        <label
                          htmlFor="authForm"
                          style={{
                            fontSize: "0.8rem",
                            marginTop: "15px",
                            color: "#337ab7",
                            fontWeight: "500",
                          }}
                        >
                          <span className="important_field">*</span> I accept
                          that gymtime can access my Credit Card for payment
                          purpose.
                        </label>
                      </div> */}
                      {errors.authFormError && (
                        <span
                          className="error"
                          style={{
                            color: "red",
                            marginTop: "10px",
                            fontSize: "0.8rem",
                          }}
                        >
                          {errors.authFormError}
                        </span>
                      )}
                    </div>

                    <div className="form__group">
                      <button className="btn btn--primary btn--full">
                        Register Account
                      </button>
                    </div>
                  </form>
                  <div className="loginWrap__footer">
                    <div className="orText">
                      <span className="orText__text">Or</span>
                    </div>
                    <div className="textBox">
                      <p className="para">
                        Already have an account ?{" "}
                        <Link
                          className="para__link"
                          onClick={() => setModal({ key: "login", flag: true })}
                        >
                          Sign In here
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignupModal;
