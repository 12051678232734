import React from 'react'
import CmsAbout from '../../pages/CMS/About/CmsAbout';
import { useParams } from 'react-router-dom';
import CmsHome from '../../pages/CMS/Home/CmsHome';
import CmsSchedule from '../../pages/CMS/Schedule/CmsSchedule';
import CmsBirthday from '../../pages/CMS/BirthDay/CmsBirthday';
import CmsCart from '../../pages/CMS/Cart/CmsCart';
import CmsBlog from '../../pages/CMS/Blog/CmsBlog';
import CmsProgram from '../../pages/CMS/Programs/CmsProgram';
import PopUp from '../../pages/CMS/PopUps/PopUp';
import Staff from '../../pages/OurStaff/Staff';
import CmsFooter from '../../pages/CMS/Footer/CmsFooter';

const ChangeRoute = () => {
    const {menuName} =  useParams();
    const {menuId}=useParams();
    let componentToRender;

    if (menuName === 'Home') {
      componentToRender = <CmsHome />;
    } else if (menuName === 'About') {
      componentToRender = <CmsAbout />;
    } 
    else if(menuName==="Schedule"){
      componentToRender=<CmsSchedule/>
    }
    else if(menuName==="Birthday & Events"){
      componentToRender=<CmsBirthday/>
    }
    else if(menuName==="Cart"){
      componentToRender=<CmsCart/>
    }
    else if(menuName==="Blog"){
      componentToRender=<CmsBlog/>
    }
    else if(menuName==="Programs"){
      componentToRender=<CmsProgram/>
    }
    else if(menuName==="POP UPS"){
      componentToRender=<PopUp/>
    }else if(menuName==="Our Staff"){
      componentToRender=<Staff/>
    }
    else if(menuName==="Footer"){
      componentToRender=<CmsFooter/>
    }
  
    return componentToRender;
}

export default ChangeRoute