import React, { useRef, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
// images
import facilityImg1 from "../../parent-assets/images/Facility-box-images/1.png";
import facilityImg2 from "../../parent-assets/images/Facility-box-images/2.png";
import facilityImg3 from "../../parent-assets/images/Facility-box-images/3.png";
import facilityImg4 from "../../parent-assets/images/Facility-box-images/4.png";
import facilityImg5 from "../../parent-assets/images/Facility-box-images/5.png";
// cooking
import cooking1 from "../../parent-assets/images/Facilities-carousel/cooking01.jpg";
import cooking2 from "../../parent-assets/images/Facilities-carousel/cooking02.jpg";
import cooking3 from "../../parent-assets/images/Facilities-carousel/cooking03.jpg";
// Gym
import gym01 from "../../parent-assets/images/Facilities-carousel/gym01.jpg";
import gym04 from "../../parent-assets/images/Facilities-carousel/gym04.jpg";
// music
import music01 from "../../parent-assets/images/Facilities-carousel/music01.jpg";
import music03 from "../../parent-assets/images/Facilities-carousel/music03.jpg";
// Art
import Art01 from "../../parent-assets/images/Facilities-carousel/Art01.jpg";
import Art02 from "../../parent-assets/images/Facilities-carousel/Art02.jpg";
import Art03 from "../../parent-assets/images/Facilities-carousel/Art03.jpg";
import Art04 from "../../parent-assets/images/Facilities-carousel/Art04.jpg";

// Birthday
import Birthday01 from "../../parent-assets/images/Facilities-carousel/Birthday01.jpg";
import Birthday02 from "../../parent-assets/images/Facilities-carousel/Birthday02.jpg";
import Birthday03 from "../../parent-assets/images/Facilities-carousel/Birthday03.jpg";
import Birthday04 from "../../parent-assets/images/Facilities-carousel/Birthday04.jpg";

import gymtimeGuy from "../../parent-assets/images/birthday-partybook-img01.png";
import { useEffect } from "react";
import { getSectionCMS } from "../../../Admin/adminApi/mastermenu";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import CarasouelShimmer from "../../parent-components/Shimmers/CarasouelShimmer";
import AboutUsShimmer from "../../../Admin/components/ShimmerUi/AboutUsShimmer";

function Facilities() {
  const token = localStorage.getItem("access_token");
  const [sectionData, setSectionData] = useState([]);
  const [showFacilityImage, setShowfacilityImage] = useState(false);
  const [galleryImage, setGalleryImage] = useState();
  const [titles, setTiles] = useState();
  const [showCarasouel, setShowCarasouel] = useState(false);
  const [showShimmer, setShowShimmer] = useState(false);
  const navigate = useNavigate();
  const owlRef = useRef(null);
  const [activeTab, setActiveTab] = useState("tab1");

  useEffect(() => {
    getFacilityImages();
    getCarasouelTitle();
  }, []);

  const getCarasouelTitle = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + `/admin/get_facilities`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const titleArray = response?.data?.data?.map((item) => item?.id)?.flat();
      setTiles(titleArray);
      if (response?.status === 201 || response?.status === 200) {
        getFacilityGalleryImage(titleArray?.[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getFacilityGalleryImage = async (id) => {
    try {
      const postData = {
        facility_id: parseInt(id),
      };
      setShowCarasouel(false);
      const responseImage = await axios.post(
        process.env.REACT_APP_BASE_URL + `/admin/get_gallery`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const imagesArray = responseImage.data.data
        .map((item) => item.image)
        .flat();
      setGalleryImage(imagesArray);
      setShowCarasouel(true);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (token) {
      const sessionTimeOut = setTimeout(() => {
        localStorage.removeItem("access_token");
        navigate("/");
      }, 86400000);
      return () => clearTimeout(sessionTimeOut);
    }
  }, [navigate]);

  const getFacilityImages = async () => {
    try {
      setShowShimmer(true);
      setShowfacilityImage(false);
      const section = await getSectionCMS(token, 170);
      setSectionData(section);
      setShowfacilityImage(true);
      setShowShimmer(false);
    } catch (error) {
      console.log("Error", error);
      setShowShimmer(false);
    }
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const handleFacilityImageClick = (tabId, id) => {
    setActiveTab(tabId);
    getFacilityGalleryImage(id);
  };

  const renderFacilityList = () => {
    const facilities = [
      { id: "tab1", facilityImg: facilityImg1, titleId: titles?.[0] },
      { id: "tab2", facilityImg: facilityImg2, titleId: titles?.[1] },
      { id: "tab3", facilityImg: facilityImg3, titleId: titles?.[2] },
      { id: "tab4", facilityImg: facilityImg4, titleId: titles?.[3] },
      { id: "tab5", facilityImg: facilityImg5, titleId: titles?.[4] },
    ];

    return facilities.map((facility, index) => (
      <li
        key={index}
        className={`facilitiesList__item tab-link  ${
          activeTab === facility.id ? "current" : ""
        }`}
        data-tab={facility.id}
        onClick={() => handleTabClick(facility.id)}
      >
        <div className="facilitiesList__card">
          <img
            className="facilitiesList__card__img"
            src={facility.facilityImg}
            alt="Facilty Img"
            onError={(e) => {
              e.target.onerror = null || "";
              e.target.src = facility.facilityImg;
            }}
            onClick={() =>
              handleFacilityImageClick(facility.id, facility?.titleId)
            }
          />
        </div>
      </li>
    ));
  };

  const renderGalleryCarousel = () => {
    const galleryImages = {
      tab1: [
        galleryImage?.[0] ? galleryImage?.[0] : gym01,
        galleryImage?.[1] ? galleryImage?.[1] : gym04,
        galleryImage?.[2] ? galleryImage?.[2] : gym01,
        galleryImage?.[3] ? galleryImage?.[3] : gym04,
        galleryImage?.[4] ? galleryImage?.[4] : gym01,
      ],

      tab2: [
        galleryImage?.[0] ? galleryImage?.[0] : cooking3,
        galleryImage?.[1] ? galleryImage?.[1] : cooking1,
        galleryImage?.[2] ? galleryImage?.[2] : cooking2,
        galleryImage?.[3] ? galleryImage?.[3] : cooking3,
        galleryImage?.[4] ? galleryImage?.[4] : cooking3,
      ],

      tab3: [
        galleryImage?.[0] ? galleryImage?.[0] : music01,
        galleryImage?.[1] ? galleryImage?.[1] : music03,
        galleryImage?.[2] ? galleryImage?.[2] : music01,
        galleryImage?.[3] ? galleryImage?.[3] : music03,
        galleryImage?.[4] ? galleryImage?.[4] : music01,
      ],

      tab4: [
        galleryImage?.[0] ? galleryImage?.[0] : Art01,
        galleryImage?.[1] ? galleryImage?.[1] : Art02,
        galleryImage?.[2] ? galleryImage?.[2] : Art03,
        galleryImage?.[3] ? galleryImage?.[3] : Art04,
        galleryImage?.[4] ? galleryImage?.[4] : Art01,
      ],

      tab5: [
        galleryImage?.[0] ? galleryImage?.[0] : Birthday01,
        galleryImage?.[1] ? galleryImage?.[1] : Birthday02,
        galleryImage?.[2] ? galleryImage?.[2] : Birthday03,
        galleryImage?.[3] ? galleryImage?.[3] : Birthday04,
        galleryImage?.[4] ? galleryImage?.[4] : Birthday04,
      ],
      // tab6: [music01,  music03, music01, music04],
    };
    const responsiveOptions = {
      0: {
        items: 1,
      },
      768: {
        items: 3,
      },
      1024: {
        items: 4,
      },
    };
    return Object.keys(galleryImages).map((tabId) => (
      <div
        key={tabId}
        id={tabId}
        className={`tab-content container ${
          activeTab === tabId ? "current" : ""
        }`}
      >
        <OwlCarousel
          ref={owlRef}
          responsive={responsiveOptions}
          className="galleryGrid owl-carousel"
          loop={true}
          arrows="true"
          dots={false}
          nav={true}
          lazyLoad={true}
          autoplay={true}
          autoplaySpeed={1000}
          smartSpeed={1000}
          navText={[
            `<img src=${require("../../parent-assets/images/enrichment-images/arrow-left.png")} />`,
            `<img src=${require("../../parent-assets/images/enrichment-images/arrow-right.png")} alt='right' />`,
          ]}
        >
          {galleryImages[tabId].map((image, imageIndex) => (
            <div
              key={imageIndex}
              className="galleryGrid__item"
              style={{ marginRight: "15px" }}
            >
              <div className="galleryGrid__card">
                <a
                  className="galleryGrid__card__link"
                  data-fancybox="gallery"
                  href={image}
                >
                  <img
                    className="galleryGrid__card__img"
                    src={image}
                    alt=""
                    style={{ width: "100%", height: "auto" }}
                  />
                </a>
              </div>
            </div>
          ))}
        </OwlCarousel>
      </div>
    ));
  };

  Fancybox.bind("[data-fancybox]", {
    Thumbs: {
      type: "classic",
    },
  });

  return (
    <>
      {showShimmer ? (
        <AboutUsShimmer component={"Birthday"} />
      ) : (
        <div className="contentwrapper">
          <section style={{ position: "relative" }}>
            <section className="siteBanner" style={{ background: "#C3D940" }}>
              <div className="siteBanner__overlay facility_sitebanner_overlay">
                <img
                  className="siteBanner__img"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  src={
                    sectionData?.length > 0 &&
                    sectionData[0]?.imageSection?.image
                  }
                  alt=""
                />
              </div>
            </section>
          </section>

          <section className="breadcrumbSec">
            <div className="container">
              <div className="breadcrumb">
                <div className="breadcrumb__item">
                  <Link
                    className="breadcrumb__link"
                    onClick={() => navigate(-1)}
                  >
                    Back to results
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className="facilitiesSec">
            <div className="container">
              <div className="facility_gymTimeGuywithName">
                <img
                  src={gymtimeGuy}
                  alt="gymtimeGuy"
                  className="gymTimeGuyIcon_fac"
                />
                <h1 className="title title--withicon title--extrabold">
                  Facilities
                </h1>
              </div>
              <div className="shortText">
                <p>{sectionData?.[1]?.descriptionSection?.description}</p>
              </div>

              <ul className="commonList facilitiesList tabs ">
                {renderFacilityList()}
              </ul>
              {showCarasouel ? (
                <div className="tab-contents">{renderGalleryCarousel()}</div>
              ) : (
                <CarasouelShimmer />
              )}
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default Facilities;
