import React, { useState, useEffect } from "react";
import Header from "../../../components/Header";
import NavigationComponent from "../../../components/NavigationComponent";
import { useParams } from "react-router-dom";
import { getMasterSubMenu, getSectionCMS } from "../../../adminApi/mastermenu";
import axios from "axios";
import { toast } from "react-toastify";
import FadeLoader from "react-spinners/FadeLoader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./cmsSchedule.css"
import Editor from 'react-simple-wysiwyg';
import EditCalender from "./EditCalender";

function CmsSchedule() {
  const { menuId } = useParams();
  const token = localStorage.getItem("access_token");
  const [activeAccordion, setActiveAccordion] = useState(0);
  const [scheduleId, setScheduleId] = useState();
  const [subMenu, setSubMenu] = useState([]);
  const [sectionData, setSectionData] = useState([]);
  const [formData, setFormData] = useState({});
  const [images, setImages] = useState([]);
  const [showHeaderLoader, setShowHeaderLoader] = useState(false);
  const [activeAccordionIndex, setActiveAccordianIndex] = useState();
  const [activeAccordianId, setActiveAccordianId] = useState();
  const [openAddHolidayCalender, setOpenAddHolidayCalender] = useState(false);
  const [showHolidayCalender, setShowHolidayCalender] = useState(false);
  const [showCalenderInputData, setShowCalenderInputData] = useState(false);
  const [calenderSession, setCalenderSession] = useState();
  const [calenderSeason, setCalenderSeason] = useState();
  const [holidaySubjectName, setHolidaySubjectName] = useState("");
  const [getPolicies, setGetPolicies] = useState();
  const [calenderFadeCalender, setCalenderFadeCalender] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [sessions, setSessions] = useState();
  const [startDateWeekday, setStartDateWeekday] = useState();
  const [days, setDays] = useState([]);
  const [calenderData, setCalenderData] = useState([]);
  const [addNewSession, setAddNewSession] = useState(false);
  const [newSession, setNewSession] = useState();

  const accordionHandler = async (index, id) => {
    setScheduleId(id);
    setActiveAccordion(index);
    setShowHeaderLoader(true);
    const section = await getSectionCMS(token, id);
    setSectionData(section);
    setShowHeaderLoader(false);
  };


  useEffect(() => {
    getMasterAboutMenuData();
  }, [images])

  useEffect(() => {
    getSessions();
    getCalenderData();
  }, []);

  const getCalenderData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "/admin/getcalendar",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const objArr = Object.entries(response?.data);
      setCalenderData(objArr);
    } catch (error) {
      console.error("Error fetching calendar data:", error);
    }
  };


  const getSessions = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + `/admin/getSessions`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          }
        }
      )
      setSessions(response?.data);
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  }

  const getMasterAboutMenuData = async () => {
    const section = await getSectionCMS(token, scheduleId ? scheduleId : "173");
    
    section?.map((item) => (
      item?.section_name === "Policies Description" &&
      setGetPolicies(item?.descriptionSection?.description)
    ))
    setSectionData(section);
    const response = await getMasterSubMenu(token, menuId);
    setSubMenu(response);
  }

  const handleFileChange = async (e, id) => {
    const sectionId = parseInt(id, 10);
    e.preventDefault();

    if (e.target.value?.split(".")?.[1]?.toLowerCase() === "jpg" ||
      e.target.value?.split(".")?.[1]?.toLowerCase() === "jpeg" ||
      e.target.value?.split(".")?.[1]?.toLowerCase() === "png") {
      try {
        const selectedFiles = e.target.files;
        if (selectedFiles && selectedFiles?.length > 0) {
          const file = selectedFiles[0];
          const reader = new FileReader();

          // Create a Promise to wait for the FileReader to load
          const loadPromise = new Promise((resolve) => {
            reader.onloadend = () => {
              resolve();
            };
          });

          // Start loading the file
          reader.readAsDataURL(file);

          // Wait for the FileReader to finish loading
          await loadPromise;

          const img = new Image();
          img.src = reader.result;

          img.onload = async () => {
            // Set the desired height and width
            const desiredHeight = 780;
            const desiredWidth = 1920;

            // Create a canvas element to draw the image with the new dimensions
            const canvas = document.createElement('canvas');
            canvas.height = desiredHeight;
            canvas.width = desiredWidth;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            // Convert the canvas content to a data URL
            const convertedFile = {
              image: canvas.toDataURL('image/jpeg'), // Use 'image/jpeg' for JPEG format
            };

            const convertedFileName = {
              name: file.name,
            };

            setImages([convertedFile]);
            setFormData({ ...formData, file: convertedFileName });

            const postData = {
              image: convertedFile.image,
              section_id: sectionId,
            };

            // Perform the API call here
            const token = localStorage.getItem('access_token');
            try {
              const response = await axios.post(
                process.env.REACT_APP_BASE_URL + `/admin/imageupload-cms`,
                postData,
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                  },
                }
              );

              if (response?.status === 200) {
                toast.success('Image uploaded successfully.');
                await accordionHandler(activeAccordionIndex, activeAccordianId);
              }
            } catch (error) {
              console.log('Error', error);
            }
          };
        }
      } catch (error) {
        console.log('Error', error);
      }
    } else {
      toast.error(`Invalid format of image, image should only be JPEG,PNG and JPG`);
      return;
    }
  };


  const addHolidayHandler = async () => {
    // session_id:5
    // season:"Fall"
    // month:"May"
    // date:["2024-05-01"]
    // text:"May holiday"
    if (calenderSession === undefined &&
      calenderSeason === undefined &&
      days?.length === 0 &&
      holidaySubjectName === ""
    ) {
      toast.error(`All the fields to add the calender is required field.`);
      return;
    }
    else if (calenderSeason === undefined) {
      toast.error(`Season is a required field`);
      return;
    }
    else if (calenderSession === undefined) {
      toast.error(`Session is a required field`);
      return;
    }
    else if (days?.length === 0) {
      toast.error(`Start date and end date is a required field.`);
      return;
    } else if (holidaySubjectName === "") {
      toast.error(`Holiday name is a required field.`);
      return;
    }



    let calenderPayload = {
      session_id: parseInt(calenderSession),
      season: calenderSeason,
      month: startDateWeekday,
      date: days,
      text: holidaySubjectName
    }
    setCalenderFadeCalender(true);
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + `/admin/calendar`, calenderPayload,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }
    )

    if (response?.status === 200 || response?.status === 201) {
      toast.success('Holiday added successfully');
      setDays([]);
      setStartDate();
      setEndDate();
    }
    if (response?.data?.error) {
      toast.error(response?.error)
    }
    setCalenderFadeCalender(false);
  }

  const handleStartDate = (date) => {
    const dateObj = new Date(date);
    const formattedDate = `${(dateObj.getMonth() + 1).toString().padStart(2, '0')}-${dateObj.getDate().toString().padStart(2, '0')}-${dateObj.getFullYear()}`;
    setStartDate(formattedDate)
    const month = dateObj.toLocaleString('en', { month: 'long' });
    setStartDateWeekday(month);
  }

  const handleEndDate = (endDate) => {
    const dateObj = new Date(endDate);
    const formattedDate = `${(dateObj.getMonth() + 1).toString().padStart(2, '0')}-${dateObj.getDate().toString().padStart(2, '0')}-${dateObj.getFullYear()}`;
    setEndDate(formattedDate)
    const startDateTemp = new Date(startDate);
    const formattedDateTemp = new Date(formattedDate);
    const differenceInMs = formattedDateTemp - startDateTemp;
    const differenceInDays = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));
    for (let i = 0; i <= differenceInDays; i++) {
      let days = addDays(startDate, i)
      days = dateFormatterYearMonthDay(days);
      setDays(prevState => [...prevState, days]);
    }
  }

  const addDays = (date, i) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + i + 1);
    const dateObj = new Date(newDate);
    const formattedDate = `${(dateObj.getMonth() + 1).toString().padStart(2, '0')}-${dateObj.getDate().toString().padStart(2, '0')}-${dateObj.getFullYear()}`;
    return formattedDate;
  }
  const dateFormatterYearMonthDay = (date) => {
    const newDate = new Date(date);
    const formattedDate = newDate.toISOString().slice(0, 10);
    return formattedDate;
  }

  const addNewSessionToggleHandler = () => {
    setAddNewSession(true);
  }
  const addNewSessionHandler = async () => {
    try {
      const postData = {
        session: newSession
      }
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + `/admin/create_session`,
        postData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      }
      )
      if (response?.status === 200 || response?.status === 201) {
        getSessions();
        setAddNewSession(false)
        toast.success("Session add successfully.");
      }
    } catch (error) {
      console.error("Error while generating new session", error)
    }
  }
  const policiesChangeHandler = async (id, data) => {
    const postData = {
      description: data,
      section_id: parseInt(id),
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + `/admin/description-cms`,
        postData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.status === 200 || response?.status === 201) {
        toast.success('Policy uploaded successfully.');
      }
    } catch (error) {
      console.log('Error', error);
    }
  }
  return (
    <>
      <div className="container--component">
        <div className="container--grid">
          <NavigationComponent />

          <div className="Adminpage">
            <Header title="Schedule" subTitle="(Manage Schedule)" />

            <section class="Adminpage__section">
              <div class="Adminpage__body">
                <div class="accordion">
                  {subMenu?.map((val, index) => {
                    return (
                      <div
                        className={`accordion__card ${activeAccordion === index ? "active" : ""
                          }`}
                        style={{ cursor: "pointer" }}
                        key={index}
                        onClick={() => {
                          accordionHandler(index, val?.id)
                          setActiveAccordianId(val?.id);
                          setActiveAccordianIndex(index);
                        }}
                      >
                        <div class="accordion__card__header">
                          <h2 class="title">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.4615 3.7712C7.72183 3.51085 7.72183 3.08874 7.4615 2.82839L5.57588 0.942771C5.53707 0.903964 5.49647 0.868044 5.45433 0.835024C5.00181 0.480349 4.37288 0.459313 3.89943 0.771917C3.82549 0.820731 3.75535 0.877684 3.69026 0.942771L1.80464 2.82839C1.54429 3.08874 1.54429 3.51085 1.80464 3.7712C2.06499 4.03155 2.4871 4.03155 2.74745 3.7712L3.99992 2.51872V12C3.99992 12.3682 4.2984 12.6666 4.66659 12.6666C5.03478 12.6666 5.33325 12.3682 5.33325 12V2.58576L6.51869 3.7712C6.77903 4.03154 7.20117 4.03154 7.4615 3.7712Z"
                                fill="white"
                              />
                              <path
                                d="M8.47162 12.2288C8.21128 12.4892 8.21128 12.9112 8.47162 13.1716L10.3572 15.0572C10.4224 15.1223 10.4925 15.1793 10.5664 15.2281C11.084 15.5698 11.7872 15.5128 12.2429 15.0572L14.1285 13.1716C14.3888 12.9112 14.3888 12.4892 14.1285 12.2288C13.8682 11.9684 13.446 11.9684 13.1857 12.2288L12.0002 13.4142V4.00004C12.0002 3.63185 11.7018 3.33337 11.3336 3.33337C10.9654 3.33337 10.6669 3.63185 10.6669 4.00004V13.4812L9.41442 12.2288C9.15408 11.9684 8.73195 11.9684 8.47162 12.2288Z"
                                fill="white"
                              />
                            </svg>
                            {val?.sub_menu_name}
                          </h2>
                          <div class="actions">
                            <button class="actions__btn accordionToggleBtn">
                              <svg
                                width="14"
                                height="8"
                                viewBox="0 0 14 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0.707131 7.28933C0.316631 6.89883 0.316631 6.26563 0.707131 5.87513L5.59933 0.987686C6.38053 0.207286 7.64633 0.207595 8.42713 0.988375L13.3175 5.87873C13.708 6.26923 13.708 6.90243 13.3175 7.29293C12.9269 7.68353 12.2938 7.68353 11.9032 7.29293L7.71763 3.10733C7.32703 2.71683 6.69393 2.71683 6.30343 3.10733L2.12133 7.28933C1.73083 7.67983 1.09763 7.67983 0.707131 7.28933Z"
                                  fill="white"
                                />
                              </svg>
                            </button>
                            <button class="actions__btn">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 5H7C5.89543 5 5 5.89543 5 7V19C5 20.1046 5.89543 21 7 21H9M15 5H17C18.1046 5 19 5.89543 19 7V9"
                                  stroke="white"
                                  strokeWidth="2.33333"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M14.9015 20.3343L12.7148 20.7716L13.1521 18.585C13.1909 18.3914 13.286 18.2136 13.4256 18.074L17.4995 14L19.4995 12L21.4864 13.9869L19.4864 15.9869L15.4125 20.0608C15.2729 20.2004 15.0951 20.2956 14.9015 20.3343Z"
                                  stroke="white"
                                  strokeWidth="2.33333"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
                                  stroke="white"
                                  strokeWidth="2.33333"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>

                        {
                          val?.sub_menu_name === "Policies" &&
                          <div class="accordion__card__body">
                            <h3 class="title">
                              Note: Compress back after completion of activity
                            </h3>
                            <div class="commonList">
                              <div class="commonList__row">

                                {
                                  sectionData?.map((item) => (
                                    item?.section_name === "Header" && <div class="commonList__row__col">
                                      <h4 class="label">Header Image:</h4>
                                      <div class="cardbox">
                                        <div class="browseBox">
                                          <div class="browseFile">
                                            <div class="browseFile__icon">
                                            </div>
                                            <h4 class="browseFile__text">
                                              Drag files to upload
                                            </h4>
                                            <p class="browseFile__ortext">or</p>
                                            <input
                                              class="hiddenInput"
                                              id="BrowseFiles"
                                              type="file"
                                              onChange={(e) => handleFileChange(e, item?.id)}
                                            />
                                            <label
                                              for="BrowseFiles"
                                              class="browseBtn"
                                            >
                                              Browse Files
                                            </label>
                                            <div class="browseFileData">
                                              <p class="browseFileData__text">
                                                Max File Size: <strong>10 MB</strong>
                                              </p>
                                              <p class="browseFileData__text">
                                                Supported file type:{" "}
                                                <strong>JPG,PNG,SVG</strong>
                                              </p>
                                            </div>
                                            <div>
                                              {showHeaderLoader === true ?
                                                <FadeLoader
                                                  color="#5497C7"
                                                  height={21}
                                                  margin={1}
                                                />
                                                : <img src={item?.imageSection?.image} alt="" className='prevImage' />}
                                            </div>
                                          </div>
                                          <div class="adminbutton__group">

                                            {/* <button class="adminbtn adminbtn-primary">
                                              Save
                                            </button> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                }
                              </div>
                              {
                                sectionData?.map((item) => item?.section_name === "Policies Description" &&
                                  <div className="edit_edit_policies_container">
                                    <Editor
                                      value={getPolicies}
                                      onChange={(e) => setGetPolicies(e.target.value)}
                                    />
                                    <div style={{ marginTop: "10px" }}>
                                      <button
                                        onClick={() => policiesChangeHandler(item?.id, getPolicies)}
                                        className="btn adminbtn-primary">Save policies</button>
                                    </div>
                                  </div>
                                )
                              }
                            </div>
                          </div>
                        }

                        {
                          val?.sub_menu_name === "Calendar" &&
                          <div class="accordion__card__body">
                            <h3 class="title">
                              Note: Compress back after completion of activity
                            </h3>
                            <div class="commonList">
                              <div class="commonList__row">
                                {
                                  sectionData?.map((item) => (
                                    item?.section_name === "Header" && <div class="commonList__row__col">
                                      <h4 class="label">Header Image:</h4>
                                      <div class="cardbox">
                                        <div class="browseBox">
                                          <div class="browseFile">
                                            <div class="browseFile__icon">
                                            </div>
                                            <h4 class="browseFile__text">
                                              Drag files to upload
                                            </h4>
                                            <p class="browseFile__ortext">or</p>
                                            <input
                                              class="hiddenInput"
                                              id="BrowseFiles-calender"
                                              type="file"
                                              onChange={(e) => handleFileChange(e, item?.id)}
                                            />
                                            <label
                                              for="BrowseFiles-calender"
                                              class="browseBtn"
                                            >
                                              Browse Files
                                            </label>
                                            <div class="browseFileData">
                                              <p class="browseFileData__text">
                                                Max File Size: <strong>10 MB</strong>
                                              </p>
                                              <p class="browseFileData__text">
                                                Supported file type:{" "}
                                                <strong>JPG,PNG,SVG</strong>
                                              </p>
                                            </div>
                                            <div>
                                              {showHeaderLoader === true ?
                                                <FadeLoader
                                                  color="#5497C7"
                                                  height={21}
                                                  margin={1}
                                                />
                                                : <img src={item?.imageSection?.image} alt="" className='prevImage' />}
                                            </div>
                                          </div>
                                          <div class="adminbutton__group">

                                            {/* <button class="adminbtn adminbtn-primary">
                                                  Save
                                                </button> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                }


                              </div>

                            </div>
                          </div>
                        }
                        {
                          val?.sub_menu_name === "Calendar" &&
                          (calenderFadeCalender ?
                            <div className="addHoliday_calender_loader_container">
                              <FadeLoader
                                color="#5497C7"
                                height={26}
                              />
                            </div>
                            : <div class="accordion__card__body">
                              <div className="create_calender_holiday_btn">
                                <button
                                  className="export_btn_attendence adminbtn-light adminbtn"
                                  onClick={() => setOpenAddHolidayCalender(!openAddHolidayCalender)}
                                >
                                  {openAddHolidayCalender ? "Close" : "Add New Holiday"}
                                </button>
                              </div>

                              {openAddHolidayCalender && <div className="add_holiday_calender_container">
                                <button
                                  className="adminbtn adminbtn-primary"
                                  onClick={() => addNewSessionToggleHandler()}
                                >+Add New Session</button>
                                {addNewSession && <div className="add_season_container">
                                  <input type="text"
                                    placeholder="Enter the session"
                                    className="adminform__input"
                                    onChange={(e) => setNewSession(e.target.value)}
                                    style={{ width: "250px" }}
                                  />
                                  <button
                                    className="adminbtn-light"
                                    style={{
                                      borderRadius: "5px",
                                      padding: "8px 15px",
                                      marginLeft: "10px"
                                    }}
                                    onClick={() => addNewSessionHandler()}
                                  >Add Session</button>
                                </div>}
                                {!addNewSession && <div className="add_holiday_calender_container_row">
                                  <div className="add_holiday_calender_container_col">
                                    <input type="text"
                                      placeholder="Enter season"
                                      className="adminform__input"
                                      onChange={(e) => setCalenderSeason(e.target.value)}
                                    />
                                  </div>


                                  <div className="add_holiday_calender_container_col">
                                    <select name="sesson"
                                      className="adminform__select"
                                      onChange={(e) => {
                                        setCalenderSession(e.target.value)
                                        setShowHolidayCalender(true)
                                      }}
                                    >
                                      <option value="2023-24" selected disabled>Select Year</option>
                                      {
                                        sessions?.map((item) => (
                                          <option value={item?.id}>{item?.session}</option>
                                        ))
                                      }

                                    </select>
                                  </div>
                                  <div className="_dual_calender_container">
                                    {
                                      showHolidayCalender &&
                                      <div className="calender_container"
                                        style={{ display: "flex", gap: "20px" }}
                                      >
                                        <DatePicker
                                          dateFormat="yyyy-MM-dd"
                                          value={startDate}
                                          onChange={(date) => handleStartDate(date)}
                                          className="adminform__input"
                                          placeholderText="Select Start Date"
                                          showMonthDropdown
                                          showYearDropdown
                                          dropdownMode="select"
                                          name={`name`}
                                        />
                                        <DatePicker
                                          dateFormat="yyyy-MM-dd"
                                          value={endDate}
                                          onChange={(date) => handleEndDate(date)}
                                          className="adminform__input"
                                          placeholderText="Select End Date"
                                          showMonthDropdown
                                          showYearDropdown
                                          dropdownMode="select"
                                          name={`name`}
                                        />
                                      </div>

                                    }
                                    {
                                      showCalenderInputData
                                      &&
                                      <input type="text" className="adminform__input" />
                                    }
                                  </div>

                                  <div className="holiday_input_container">
                                    <input type="text"
                                      placeholder="Enter the holiday name"
                                      className="adminform__input"
                                      onChange={(e) => setHolidaySubjectName(e.target.value)}
                                    />
                                  </div>
                                </div>}
                                <button
                                  className="export_btn_attendence adminbtn-light adminbtn"
                                  style={{ marginTop: "10px" }}
                                  onClick={() => addHolidayHandler()}
                                >Save
                                </button>
                              </div>}

                              <EditCalender />
                            </div>)
                        }
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default CmsSchedule;
