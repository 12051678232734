import React, { useEffect, useState } from 'react'
import { ReactComponent as CloseIcon } from "../../../assets/images/icons/close-icon.svg";
import { toast } from 'react-toastify';
import axios from 'axios';
const EditCalenderHoliday = ({ setHolidayEditModal, holiday, getCalenderData, type }) => {
    const token = localStorage.getItem("access_token");
    const [holidayName, setHolidayName] = useState();
    const [holidayDate, setHolidayDate] = useState();
    useEffect(() => {
        setHolidaysFields();
    }, [holiday])

    const setHolidaysFields = () => {
        setHolidayName(holiday?.text);
        setHolidayDate(holiday?.date);
    }

    const editCalenderHandler = async () => {
        try {
            const postData = {
                calendarId: 60,
                text: holidayName,
                sessionId: holiday?.session_id,
                dateId: holiday?.id,
                date: [holidayDate],
                season: holiday?.season
            };

            const response = await axios.put(
                process.env.REACT_APP_BASE_URL + "/admin/updatecalendar",
                postData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
            )
            if (response?.status === 201 || response?.status === 200) {
                toast.success("Calender updated successfully.");
                setHolidayEditModal(false);
                getCalenderData();
            }
        } catch (error) {
            console.error(error);
            toast.error(error);
            setHolidayEditModal(false);
        }
    }

    const deleteMonthHolidayHandler = async () => {
        try {
            const postData = {
                dateId: holiday?.id,
                sessionId: 123
            };

            const response = await axios.post(
                process.env.REACT_APP_BASE_URL + "/admin/deletecalendar",
                postData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
            )
            if(response?.status===200 || response?.status===201){
                toast.error("Holiday deleted successfully");
                setHolidayEditModal(false);
                getCalenderData();
            }
        } catch (error) {
            console.error("Error while deleting holiday", error);
            toast.error(error);
            setHolidayEditModal(false);
        }
    }
    return (
        <>
            <div
                className="Admin-modal Admin-modal--open"
                id="modalDelete"
                role="dialog"
            >
                <div className="Admin-modal__dialog Admin-modal__dialog--md">
                    <div className="Admin-modal__content">
                        <div className="Admin-modal__head">
                            <h2 className="Admin-modal__head--title">{type} Calender</h2>
                            <button
                                className="Admin-modal__close"
                                onClick={() => setHolidayEditModal(false)}
                            >
                                <CloseIcon />
                            </button>
                        </div>
                        <div className="Admin-modal__body">

                            <div className="holiday_container_edit">
                                <input type="text"
                                    value={holidayDate}
                                    className='adminform__input'
                                    onChange={(e) => setHolidayDate(e.target.value)}
                                />

                                <input type="text"
                                    placeholder='Enter holiday'
                                    className='adminform__input'
                                    value={holidayName}
                                    onChange={(e) => setHolidayName(e.target.value)}
                                />
                            </div>
                            <div className="btn_conatiner">
                                {type === "Edit" ?
                                    <button className='btn adminbtn-primary'
                                        onClick={() => editCalenderHandler()}
                                    >Edit</button> :
                                    <button className='btn adminbtn-primary'
                                        onClick={() => deleteMonthHolidayHandler()}
                                    >Delete</button>
                                }
                                <button className='btn adminbtn-primary'
                                    onClick={() => setHolidayEditModal(false)}
                                >Cancel</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default EditCalenderHoliday